import "swiper/css";
import "swiper/css/pagination";

import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Menu,
  Message,
  Modal,
  Segment,
  Transition,
  TransitionablePortal,
} from "semantic-ui-react";
import { Mousewheel, Pagination } from "swiper";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { isDesktop, isMobile } from "react-device-detect";
import { useNavigate, useParams } from "react-router-dom";

import CarnawaFooterPage from "../../components/CarnawaFooterPage";
import { SERVICE_TYPE } from "../../constants/Consts";
import ServiceOutModal from "../popup/ServiceOutModal";
import Typewriter from "typewriter-effect";
import _ from "lodash";
import background from "../../resource/background/background.mp4";
import logo from "../../images/carnawa_logo.png";
import step1 from "../../images/v2/step1.png";
import step2 from "../../images/v2/step2.png";
import step3 from "../../images/v2/step3.png";

const ModalLegalInformation = ({ open, close }) => {
  return (
    <Modal open={open} onClose={close}>
      <Modal.Content>
        <Header
          as={"h1"}
          style={{
            fontFamily: "paybooc-Light",
            fontSize: isMobile ? ".9rem" : "1.3rem",
          }}
        >
          법률 정보 [자동차관리법 제58조의6]
          <Divider />
          <Header.Subheader style={{ fontSize: "1.1rem", marginTop: "1vh" }}>
            <strong>제58조의6(매매 계약의 해제 등)</strong>
            <br />
            <br />
            ① 자동차매매업자의 매매 또는 매매 알선으로 매매 계약을 맺은 자동차
            매수인은 해당 자동차가 다음 각 호의 어느 하나에 해당하는 경우에는
            자동차인도일부터 30일 이내에 해당 매매 계약을 해제할 수 있다.
            <br />
            1. 해당 자동차의 주행거리, 사고 또는 침수 사실이 제58조제1항제1호의
            고지 내용과 다른 경우
            <br />
            2. 제58조제1항제1호 또는 제2호에 따른 사항을 거짓으로 고지하거나
            고지하지 아니한 경우
            <br />
            <br />
            ② 자동차 매수인은 제1항에 따라 매매 계약을 해제한 경우에는 해당
            자동차를 즉시 자동차매매업자에게 반환하여야 한다.
            <br />
            <br />③ 자동차매매업자는 제2항에 따른 자동차의 반환과 동시에 이미
            지급받은 매매금액을 자동차 매수인에게 반환하여야 한다.
          </Header.Subheader>
        </Header>
      </Modal.Content>
      <Modal.Actions>
        <Button floated={"right"} clolor={"red"} onClick={close}>
          닫기
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

const Home = () => {
  const params = useParams();
  const navigate = useNavigate();
  const mediaRef = useRef();
  const [serviceType, setServiceType] = useState(params.service);
  const [, setVh] = useState(0);
  const [carnawaSwiper, setCarnawaSwiper] = useState(null);
  const [carnawaTypewriter, setCarnawaTypewriter] = useState(null);
  const [menuVisible, setMenuVisible] = useState(true);
  const [logoVisible, setLogoVisible] = useState(true);

  const [legalInformation, setLegalInformation] = useState({
    open: false,
    close: () => {
      setLegalInformation((prevState) => {
        return {
          ...prevState,
          open: false,
        };
      });
    },
  });

  const [mobileMenuPortal, setMobileMenuPortal] = useState({
    open: false,
    close: () => {
      setMobileMenuPortal((prevState) => {
        return {
          ...prevState,
          open: false,
        };
      });
    },
  });

  useLayoutEffect(() => {
    setScreenSize();
    window.addEventListener("resize", () => {
      setScreenSize();
    });
    window.addEventListener("touchend", () => {
      setScreenSize();
    });
  }, []);

  const typeText = () => {
    carnawaTypewriter &&
      carnawaTypewriter
        .changeDeleteSpeed(5)
        .deleteAll()
        .typeString(
          params.service === SERVICE_TYPE.CARNAWA
            ? "세상에 없던 중고차 가격비교 서비스"
            : "중고차 숨은 보상금 찾기"
        )
        .callFunction(() => {
          setLogoVisible(!logoVisible);
        })
        .start();
  };

  useEffect(() => {
    typeText();
  }, [carnawaTypewriter, serviceType]);

  const setScreenSize = () => {
    let currentHeight = window.innerHeight;
    let currentVh = currentHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${currentVh}px`);
    setVh(currentVh);
  };

  const changeService = (moveService) => {
    carnawaSwiper.slideTo(0, 500);
    setServiceType(moveService);
    navigate(`/${moveService}`);
  };

  const requestReport = () => {
    navigate(`/request/${serviceType}`);
  };

  const ContentGroup = {
    carnawa: {
      header: (
        <SwiperSlide key={`swiper-slide-header`}>
          <Container text>
            {serviceType === SERVICE_TYPE.CARNAWA && (
              <Header
                as={"h1"}
                content={
                  <Typewriter
                    options={{
                      delay: 70,
                    }}
                    onInit={(typewriter) => {
                      setCarnawaTypewriter(typewriter);
                    }}
                  />
                }
                style={{
                  fontFamily: "GmarketSansMedium",
                  fontSize: isMobile ? "1.4rem" : "1.7em",
                  fontWeight: "normal",
                }}
              />
            )}

            <Transition
              animation={"jiggle"}
              duration={800}
              visible={logoVisible}
            >
              <Image
                src={logo}
                style={{
                  filter: `drop-shadow(0 1px 1px #ccc)`,
                  marginBottom: "5vh",
                }}
              />
            </Transition>

            <Header
              as={"h1"}
              style={{
                fontFamily: "Black Han Sans",
                fontWeight: 300,
                fontStyle: "italic",
                fontSize: isMobile ? "2rem" : "3rem",
                lineHeight: isMobile ? "1.7rem" : "2.5rem",
              }}
            >
              중고차,
              <br />
              1원도 손해보지 마세요.
            </Header>
            <p
              style={{
                fontSize: isMobile ? ".9rem" : "1.5rem",
                fontFamily: "paybooc-Light",
                marginTop: "1vh",
              }}
            >
              같은 차라도{" "}
              <span style={{ color: "rgb(255, 108, 0)" }}>
                플랫폼마다 가격이 다르다?
              </span>
              <br />
              번호판 입력하고{" "}
              <span style={{ color: "rgb(255, 108, 0)" }}>
                최저가를 확인해보세요.
              </span>
              <br />
              중고차 최저가를 찾아주는{" "}
              <span style={{ color: "rgb(255, 108, 0)" }}>카나와</span>입니다.
            </p>

            <Button
              color={"black"}
              size={"big"}
              icon={"clipboard check"}
              content={"지금 바로 신청하기"}
              style={{ fontFamily: "paybooc-Light", marginTop: "5vh" }}
              onClick={requestReport}
            />
          </Container>
        </SwiperSlide>
      ),
      slide1: (
        <SwiperSlide key={`swiper-slide-carnawa-slide1`}>
          <Container text>
            <Header
              as={isMobile ? "h2" : "h1"}
              style={{ fontFamily: "paybooc-Medium" }}
            >
              카나와 가격 비교 서비스란?
            </Header>
            <Divider />
            <Segment basic textAlign={"left"}>
              <p
                style={{
                  fontFamily: "paybooc-Light",
                  fontSize: isMobile ? "1.2rem" : "1.5rem",
                  margin: 0,
                  lineHeight: isMobile ? "2rem" : "2.5rem",
                }}
              >
                같은 번호의 차량이라도{" "}
                <span style={{ color: "#0084ff" }}>
                  플랫폼마다 가격이 다르다
                </span>
                는 사실을 아시나요?
                <br />
                카나와는 국내 중고차 판매 플랫폼들의 차량 판매가를 수집하여,
                소비자들이{" "}
                <span style={{ color: "#0084ff" }}>가장 저렴한 가격</span>에
                차량을 구매하실 수 있도록{" "}
                <span style={{ color: "#0084ff" }}>가격 비교 리포트</span>를
                제공해드립니다.
              </p>
            </Segment>
          </Container>
        </SwiperSlide>
      ),
      slide2: (
        <SwiperSlide key={`swiper-slide-carnawa-slide2`}>
          <Container text>
            <Header
              as={isMobile ? "h2" : "h1"}
              style={{ fontFamily: "paybooc-Medium" }}
            >
              리포트 요청은 어떻게 하나요?
            </Header>
            <Divider />
            <Grid>
              <Grid.Row>
                <Grid.Column width={16} verticalAlign={"middle"}>
                  <Image src={step1} size={"medium"} centered />
                  <p
                    style={{
                      fontFamily: "paybooc-Light",
                      fontSize: isMobile ? "1.2rem" : "1.5rem",
                      margin: "6vh 0 0 0",
                    }}
                  >
                    가격 비교를 원하는 차량 번호를 입력해주세요.
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </SwiperSlide>
      ),
      slide3: (
        <SwiperSlide key={`swiper-slide-carnawa-slide3`}>
          <Container text>
            <Grid>
              <Grid.Row>
                <Grid.Column width={16} verticalAlign={"middle"}>
                  <Image src={step2} size={"medium"} centered />
                  <p
                    style={{
                      fontFamily: "paybooc-Light",
                      fontSize: isMobile ? "1.2rem" : "1.5rem",
                      margin: "6vh 0 0 0",
                    }}
                  >
                    카나와가 대상 플랫폼에서 해당 차량을 판매중인지 확인합니다.
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </SwiperSlide>
      ),
      slide4: (
        <SwiperSlide key={`swiper-slide-carnawa-slide4`}>
          <Container text>
            <Grid>
              <Grid.Row>
                <Grid.Column width={16} verticalAlign={"middle"}>
                  <Image src={step3} size={"medium"} centered />
                  <p
                    style={{
                      fontFamily: "paybooc-Light",
                      fontSize: isMobile ? "1.2rem" : "1.5rem",
                      margin: "5vh 0 0 0",
                    }}
                  >
                    <span style={{ color: "#0088d6" }}>STEP2</span>를 통해
                    수집된
                    <br />
                    데이터를 기반으로 작성된 리포트를
                    <br />
                    <span style={{ color: "#009aa8" }}>
                      고객님께 발송해드립니다.
                    </span>
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </SwiperSlide>
      ),

      footer: (
        <SwiperSlide key={`swiper-slide-footer`}>
          <CarnawaFooterPage />
        </SwiperSlide>
      ),
    },

    repair: {
      header: (
        <SwiperSlide key={`swiper-slide-header`}>
          <Container text>
            {serviceType === SERVICE_TYPE.ACCIDENT_HISTORY && (
              <Header
                as={"h1"}
                content={
                  <Typewriter
                    options={{
                      delay: 70,
                    }}
                    onInit={(typewriter) => {
                      setCarnawaTypewriter(typewriter);
                    }}
                  />
                }
                style={{
                  fontFamily: "GmarketSansMedium",
                  fontSize: isMobile ? "1.4rem" : "1.7em",
                  fontWeight: "normal",
                }}
              />
            )}

            <Transition
              animation={"jiggle"}
              duration={800}
              visible={logoVisible}
              reactKey={"repairLogo"}
            >
              <Image
                src={logo}
                style={{
                  filter: `drop-shadow(0 1px 1px #ccc)`,
                  marginBottom: "5vh",
                }}
              />
            </Transition>

            <Header
              as={"h1"}
              style={{
                fontFamily: "Black Han Sans",
                fontWeight: 300,
                fontStyle: "italic",
                fontSize: isMobile ? "2rem" : "3rem",
                lineHeight: isMobile ? "1.7rem" : "2.5rem",
              }}
            >
              큰 맘 먹고 구입한 내 차<br />
              알고보니 사고차량?!
            </Header>
            <p
              style={{
                fontSize: isMobile ? ".9rem" : "1.5rem",
                fontFamily: "paybooc-Light",
                marginTop: "1vh",
              }}
            >
              번호판만 입력하면{" "}
              <span style={{ color: "rgb(255, 108, 0)" }}>
                사고 이력 조회가 한번에!
              </span>
              <br />
              몰랐던 사고 이력이 있다면,{" "}
              <span style={{ color: "rgb(255, 108, 0)" }}>
                보상금까지 받아보세요.
              </span>
              <br />
              모르면 손해보는 중고차 비밀!
            </p>

            <Button
              color={"black"}
              size={"big"}
              icon={"clipboard check"}
              content={"지금 바로 신청하기"}
              style={{ fontFamily: "paybooc-Light", marginTop: "5vh" }}
              onClick={requestReport}
            />
          </Container>
        </SwiperSlide>
      ),
      slide1: (
        <SwiperSlide key={`swiper-slide-repair-slide1`}>
          <Container text>
            <Header
              as={isMobile ? "h2" : "h1"}
              style={{ fontFamily: "paybooc-Medium" }}
            >
              카나와 숨은 보상금 찾기란?
            </Header>
            <Divider />
            <Segment basic textAlign={"left"}>
              <p
                style={{
                  fontFamily: "paybooc-Light",
                  fontSize: isMobile ? "1.2rem" : "1.5rem",
                  margin: 0,
                  lineHeight: isMobile ? "2rem" : "2.5rem",
                }}
              >
                중고차 소비자들의 권익보호를 위해 <br />
                구매한 중고차의&nbsp;
                <span style={{ color: "#0084ff" }}>사고 및 정비내역</span>을
                제공해드리고, 차량 구매시&nbsp;
                <span style={{ color: "#0084ff" }}>고지받지 못한 사고이력</span>
                이 발견될 경우 구매자가 볼 수 있는 손해를&nbsp;
                <span style={{ color: "#0084ff" }}>
                  성능 책임보험을 통해 보상{" "}
                </span>
                받을 수 있도록 안내해 드리는 서비스 입니다.
              </p>
            </Segment>
          </Container>
        </SwiperSlide>
      ),
      slide2: (
        <SwiperSlide key={`swiper-slide-repair-slide2`}>
          <Container text>
            <Header
              as={isMobile ? "h2" : "h1"}
              style={{ fontFamily: "paybooc-Medium" }}
            >
              리포트 요청은 어떻게 하나요?
            </Header>
            <Divider />
            <Grid>
              <Grid.Row>
                <Grid.Column width={16} verticalAlign={"middle"}>
                  <Image src={step1} size={"medium"} centered />
                  <p
                    style={{
                      fontFamily: "paybooc-Light",
                      fontSize: isMobile ? "1.2rem" : "1.5rem",
                      margin: "6vh 0 0 0",
                    }}
                  >
                    고객님의 개인정보 보호를 위해 <br />
                    차량의 실제 소유주 정보를 확인합니다.
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </SwiperSlide>
      ),
      slide3: (
        <SwiperSlide key={`swiper-slide-repair-slide3`}>
          <Container text>
            <Grid>
              <Grid.Row>
                <Grid.Column width={16} verticalAlign={"middle"}>
                  <Image src={step2} size={"medium"} centered />
                  <p
                    style={{
                      fontFamily: "paybooc-Light",
                      fontSize: isMobile ? "1.2rem" : "1.5rem",
                      margin: "6vh 0 0 0",
                    }}
                  >
                    명확한 사고유무 분석을 위해
                    <br />각 기관으로부터 차량 데이터를 수집합니다.
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </SwiperSlide>
      ),
      slide4: (
        <SwiperSlide key={`swiper-slide-repair-slide4`}>
          <Container text>
            <Grid>
              <Grid.Row>
                <Grid.Column width={16} verticalAlign={"middle"}>
                  <Image src={step3} size={"medium"} centered />
                  <p
                    style={{
                      fontFamily: "paybooc-Light",
                      fontSize: isMobile ? "1.2rem" : "1.5rem",
                      margin: "5vh 0 0 0",
                    }}
                  >
                    <span style={{ color: "#0088d6" }}>STEP2</span>를 통해
                    수집된
                    <br />
                    데이터를 기반으로 작성된 리포트를
                    <br />
                    <span style={{ color: "#009aa8" }}>
                      고객님께 발송해드립니다.
                    </span>
                  </p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </SwiperSlide>
      ),
      slide5: {
        desktop: [
          <SwiperSlide key={`swiper-slide-repair-slide5`}>
            <Container text>
              <Header
                as={isMobile ? "h3" : "h1"}
                style={{ fontFamily: "paybooc-Medium" }}
              >
                손해 보상은 어느 경우에 가능한가요?
              </Header>
              <Divider />
              <Segment basic textAlign={"left"}>
                <p
                  style={{
                    fontFamily: "paybooc-Light",
                    fontSize: isMobile ? "1rem" : "1.5rem",
                    margin: 0,
                    lineHeight: isMobile ? "1.7rem" : "2.5rem",
                  }}
                >
                  차량 구매 당시에 차량판매자(딜러)로 부터 고지받은 차량의
                  성능점검 기록부와 실제 차량의 상태가 다를 경우, 차량
                  인도일로부터 30일 이내에 매매계약을 해제할 수 있습니다.
                  (개인간 거래에는 적용 불가)
                </p>
                <Message>
                  <Header
                    as={"h1"}
                    style={{
                      fontFamily: "paybooc-Light",
                      fontSize: isMobile ? "0.9rem" : "1.3rem",
                    }}
                  >
                    중고차 판매 시 판매자가 고지해야 하는 사항
                    <Header.Subheader
                      style={{
                        fontSize: isMobile ? "0.8rem" : "1.2rem",
                        marginTop: "1vh",
                      }}
                    >
                      - 자동차성능상태 점검 내용 (점검일로부터 120일 이내의 점검
                      사진을 포함)
                      <br />
                      - 압류 및 저당권의 등록 여부
                      <br />
                      - 수수료 또는 요금
                      <br />- 매수인이 원하는 경우 자동차 가격을 조사/산정한
                      내용
                    </Header.Subheader>
                  </Header>
                  <Message.Content
                    as={"h6"}
                    style={{ textAlign: "right", marginTop: 10 }}
                  >
                    [자동차관리법 제58조 제1항]
                  </Message.Content>
                </Message>

                <List
                  style={{
                    fontSize: isMobile ? ".8rem" : "1.2rem",
                    marginTop: "2vh",
                  }}
                >
                  <List.Item>
                    - <span style={{ color: "#0088d6" }}>주행거리, 사고</span>{" "}
                    또는{" "}
                    <span style={{ color: "#0088d6" }}>
                      침수 사실이 다른 경우
                    </span>
                  </List.Item>
                  <List.Item>
                    -{" "}
                    <span style={{ color: "#0088d6" }}>
                      고지 사항을 거짓으로 고지
                    </span>
                    하거나{" "}
                    <span style={{ color: "#0088d6" }}>고지하지 않은 경우</span>
                  </List.Item>
                </List>

                <p
                  style={{
                    fontFamily: "paybooc-Medium",
                    fontSize: isMobile ? "1rem" : "1.5rem",
                    margin: "2vh 0",
                    lineHeight: isMobile ? "1.7rem" : "2.5rem",
                    fontStyle: "italic",
                  }}
                >
                  해당 차량을 판매한 딜러는 자동차를 반환받고 지급받은
                  매매금액을 환불해야합니다. (자동차관리법 제58조의6)
                </p>
              </Segment>
            </Container>
          </SwiperSlide>,
        ],
        mobile: [
          <SwiperSlide key={`swiper-slide-repair-slide5-mobile1`}>
            <Container text>
              <Header
                as={isMobile ? "h3" : "h1"}
                style={{ fontFamily: "paybooc-Medium" }}
              >
                손해 보상은 어느 경우에 가능한가요?
              </Header>
              <Divider />
              <Segment basic textAlign={"left"}>
                <p
                  style={{
                    fontFamily: "paybooc-Light",
                    fontSize: isMobile ? "1rem" : "1.5rem",
                    margin: 0,
                    lineHeight: isMobile ? "1.7rem" : "2.5rem",
                  }}
                >
                  자동차매매업자가는 자동차관리법에 의거하여 자동차 매매 계약시
                  아래 내용은 반드시 차량구매자에게 고지하여야합니다.
                </p>
                <Message>
                  <Header
                    as={"h1"}
                    style={{
                      fontFamily: "paybooc-Light",
                      fontSize: isMobile ? "0.9rem" : "1.3rem",
                    }}
                  >
                    중고차 판매 시 판매자가 고지해야 하는 사항
                    <Header.Subheader
                      style={{
                        fontSize: isMobile ? "0.9rem" : "1.2rem",
                        marginTop: "1vh",
                      }}
                    >
                      <List size={"tiny"}>
                        <List.Item>
                          <List.Icon name={"triangle right"} />
                          <List.Content style={{ fontFamily: "paybooc-Light" }}>
                            자동차성능상태 점검 내용 (점검일로부터 120일 이내의
                            점검 사진을 포함)
                          </List.Content>
                        </List.Item>
                        <List.Item>
                          <List.Icon name={"triangle right"} />
                          <List.Content style={{ fontFamily: "paybooc-Light" }}>
                            압류 및 저당권의 등록 여부
                          </List.Content>
                        </List.Item>
                        <List.Item>
                          <List.Icon name={"triangle right"} />
                          <List.Content style={{ fontFamily: "paybooc-Light" }}>
                            수수료 또는 요금
                          </List.Content>
                        </List.Item>
                        <List.Item>
                          <List.Icon name={"triangle right"} />
                          <List.Content style={{ fontFamily: "paybooc-Light" }}>
                            매수인이 원하는 경우 자동차 가격을 조사/산정한 내용
                          </List.Content>
                        </List.Item>
                      </List>
                    </Header.Subheader>
                  </Header>
                  <Message.Content
                    as={"h5"}
                    style={{ textAlign: "right", marginTop: 10 }}
                  >
                    [자동차관리법 제58조 제1항]
                  </Message.Content>
                </Message>
              </Segment>
            </Container>
          </SwiperSlide>,

          <SwiperSlide key={`swiper-slide-repair-slide5-mobile2`}>
            <Container text textAlign={"left"}>
              <p
                style={{
                  fontFamily: "paybooc-Light",
                  fontSize: isMobile ? "1rem" : "1.5rem",
                  margin: 0,
                  lineHeight: isMobile ? "1.7rem" : "2.5rem",
                }}
              >
                해당 내용을 고지받았더라도, 아래 사항에 해당된다면
                자동차인도일부터 30일 이내에 해당 매매 계약을 해제할 수
                있습니다. (자동차관리법 제58조의6)
              </p>

              <List
                style={{
                  fontSize: isMobile ? ".9rem" : "1.2rem",
                  marginTop: "2vh",
                }}
              >
                <List.Item>
                  <List.Icon name={"triangle right"} />
                  <List.Content>
                    <span style={{ color: "#0088d6" }}>주행거리, 사고</span>{" "}
                    또는{" "}
                    <span style={{ color: "#0088d6" }}>
                      침수 사실이 다른 경우
                    </span>
                  </List.Content>
                </List.Item>

                <List.Item>
                  <List.Icon name={"triangle right"} />
                  <List.Content>
                    <span style={{ color: "#0088d6" }}>
                      고지 사항을 거짓으로 고지
                    </span>
                    하거나{" "}
                    <span style={{ color: "#0088d6" }}>고지하지 않은 경우</span>
                  </List.Content>
                </List.Item>
              </List>

              {/*<p style={{*/}
              {/*  fontFamily: 'paybooc-Medium',*/}
              {/*  fontSize: (isMobile ? '1rem' : '1.5rem'),*/}
              {/*  margin: '2vh 0',*/}
              {/*  lineHeight: (isMobile ? '1.7rem' : '2.5rem'),*/}
              {/*  fontStyle: 'italic'*/}
              {/*}}>*/}
              {/*  해당 차량을 판매한 딜러는 자동차를 반환받고 지급받은 매매금액을 환불해야합니다.*/}
              {/*  (자동차관리법 제58조의6)*/}
              {/*</p>*/}
            </Container>
          </SwiperSlide>,
        ],
      },
      slide6: (
        <SwiperSlide key={`swiper-slide-repair-slide6`}>
          <Container text>
            <Header
              as={isMobile ? "h2" : "h1"}
              style={{ fontFamily: "paybooc-Medium" }}
            >
              보상금은 어떻게 산정되나요?
            </Header>
            <Divider />
            <Segment basic textAlign={"left"}>
              <p
                style={{
                  fontFamily: "paybooc-Light",
                  fontSize: isMobile ? "1rem" : "1.5rem",
                  margin: 0,
                  lineHeight: isMobile ? "1.7rem" : "2.5rem",
                }}
              >
                <span style={{ color: "#0088d6" }}>
                  차량 구매 후 30일 이내에 고지되지 않은 사고이력을 발견한 경우
                </span>{" "}
                매수자는 <strong>차량 환불을 요구</strong>할 수 있습니다. <br />
              </p>
              <p
                style={{
                  fontFamily: "paybooc-Light",
                  fontSize: isMobile ? "1rem" : "1.5rem",
                  margin: "2vh 0",
                  lineHeight: isMobile ? "1.7rem" : "2.5rem",
                }}
              >
                보험사 측에서는 차량가액의 5 ~ 7% 보험금을 보상 받을 수
                있습니다.
              </p>

              {isDesktop && (
                <Message>
                  <Header
                    as={"h1"}
                    style={{
                      fontFamily: "paybooc-Light",
                      fontSize: isMobile ? ".9rem" : "1.3rem",
                    }}
                  >
                    법률 정보 [자동차관리법 제58조의6]
                    <Divider />
                    <Header.Subheader
                      style={{ fontSize: "1.1rem", marginTop: "1vh" }}
                    >
                      <strong>제58조의6(매매 계약의 해제 등)</strong>
                      <br />
                      <br />
                      ① 자동차매매업자의 매매 또는 매매 알선으로 매매 계약을
                      맺은 자동차 매수인은 해당 자동차가 다음 각 호의 어느
                      하나에 해당하는 경우에는 자동차인도일부터 30일 이내에 해당
                      매매 계약을 해제할 수 있다.
                      <br />
                      1. 해당 자동차의 주행거리, 사고 또는 침수 사실이
                      제58조제1항제1호의 고지 내용과 다른 경우
                      <br />
                      2. 제58조제1항제1호 또는 제2호에 따른 사항을 거짓으로
                      고지하거나 고지하지 아니한 경우
                      <br />
                      <br />
                      ② 자동차 매수인은 제1항에 따라 매매 계약을 해제한 경우에는
                      해당 자동차를 즉시 자동차매매업자에게 반환하여야 한다.
                      <br />
                      <br />③ 자동차매매업자는 제2항에 따른 자동차의 반환과
                      동시에 이미 지급받은 매매금액을 자동차 매수인에게
                      반환하여야 한다.
                    </Header.Subheader>
                  </Header>
                </Message>
              )}

              {isMobile && (
                <Button
                  color={"orange"}
                  style={{
                    fontFamily: "paybooc-Light",
                    fontSize: isMobile ? ".9rem" : "1.3rem",
                  }}
                  onClick={() => {
                    setLegalInformation((prevState) => {
                      return {
                        ...prevState,
                        open: true,
                      };
                    });
                  }}
                >
                  <Icon name={"bookmark"} /> 법률 정보 [자동차관리법 제58조의6]
                </Button>
              )}
            </Segment>
          </Container>
        </SwiperSlide>
      ),
      slide7: (
        <SwiperSlide key={`swiper-slide-repair-slide7`}>
          <Container text>
            <Header
              as={isMobile ? "h2" : "h1"}
              style={{ fontFamily: "paybooc-Medium" }}
            >
              보상 신청은 어떻게 하나요?
            </Header>
            <Divider />
            <Segment basic textAlign={"left"}>
              <p
                style={{
                  fontFamily: "paybooc-Light",
                  fontSize: isMobile ? "1rem" : "1.5rem",
                  margin: 0,
                  lineHeight: isMobile ? "1.7rem" : "2.5rem",
                }}
              >
                <strong>고지받지 못한 사고이력이 발견</strong>될 경우
              </p>
              <p
                style={{
                  fontFamily: "paybooc-Light",
                  fontSize: isMobile ? "1rem" : "1.5rem",
                  margin: 0,
                  lineHeight: isMobile ? "1.7rem" : "2.5rem",
                }}
              >
                <span style={{ color: "#0088d6" }}>한국자동차소비자협회</span>를
                통해서 <strong>보상 절차</strong>를 안내받으실 수 있습니다.
              </p>

              <div
                style={{
                  fontFamily: "paybooc-Light",
                  fontSize: isMobile ? "1rem" : "1.2rem",
                  marginTop: 20,
                  lineHeight: isMobile ? "1.7rem" : "2.5rem",
                }}
              >
                <Grid centered divided stackable>
                  <Grid.Row>
                    <Grid.Column width={5} textAlign={"center"}>
                      (사)한국자동차소비자협회
                    </Grid.Column>
                    <Grid.Column width={4} textAlign={"center"}>
                      <a
                        href={"https://car114.org/32"}
                        target={"_blank"}
                        rel="noreferrer"
                        style={{ color: "#000" }}
                      >
                        <Icon name={"external"} />
                        소비자상담실
                      </a>
                    </Grid.Column>
                    <Grid.Column width={5} textAlign={"center"}>
                      <Icon name={"phone"} />
                      고객센터 1577-1263
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
            </Segment>
          </Container>
        </SwiperSlide>
      ),
      footer: (
        <SwiperSlide key={`swiper-slide-footer`}>
          <CarnawaFooterPage />
        </SwiperSlide>
      ),
    },
  };

  return (
    <>
      <ServiceOutModal />
      <Transition animation={"slide down"} visible={menuVisible}>
        <Menu fixed={"top"} inverted>
          {isDesktop && (
            <Container>
              <Menu.Item
                as="a"
                content={
                  <span
                    style={{
                      fontWeight: 300,
                      color:
                        serviceType === SERVICE_TYPE.ACCIDENT_HISTORY
                          ? "#000"
                          : null,
                    }}
                  >
                    숨은 보상금 찾기
                  </span>
                }
                active={serviceType === SERVICE_TYPE.ACCIDENT_HISTORY}
                style={{
                  background:
                    serviceType === SERVICE_TYPE.ACCIDENT_HISTORY
                      ? "#fff"
                      : null,
                }}
                onClick={() => changeService(SERVICE_TYPE.ACCIDENT_HISTORY)}
              />

              <Menu.Item
                as="a"
                content={
                  <span
                    style={{
                      fontWeight: 300,
                      color:
                        serviceType === SERVICE_TYPE.CARNAWA ? "#000" : null,
                    }}
                  >
                    중고차 가격비교
                  </span>
                }
                active={serviceType === SERVICE_TYPE.CARNAWA}
                style={{
                  background:
                    serviceType === SERVICE_TYPE.CARNAWA ? "#fff" : null,
                }}
                onClick={() => changeService(SERVICE_TYPE.CARNAWA)}
              />

              <Menu.Item
                as="a"
                href={process.env.REACT_APP_CARMON_SITE_URL}
                target={"_blank"}
                content={"내차 팔기"}
              />

              {/*<Menu.Item as='a' href={process.env.REACT_APP_CARMON_DOT_COM_SITE_URL}*/}
              {/*           target={'_blank'}*/}
              {/*           content={'내차 사기'} />*/}

              <Menu.Item position="right">
                <Button
                  as="a"
                  icon={"clipboard check"}
                  content={
                    serviceType === SERVICE_TYPE.CARNAWA
                      ? "최저가 확인하기"
                      : "숨은 보상금 찾기"
                  }
                  color={"orange"}
                  style={{
                    fontFamily: "paybooc-Light",
                    // background:'#fff',
                    // color:'#000'
                  }}
                  onClick={requestReport}
                />
              </Menu.Item>
            </Container>
          )}
          {isMobile && (
            <Container>
              <Menu.Item
                as="a"
                onClick={() => {
                  setMobileMenuPortal((prevState) => {
                    return {
                      ...prevState,
                      open: !prevState.open,
                    };
                  });
                }}
              >
                <Icon name={"bars"} /> 메뉴
              </Menu.Item>

              <Menu.Item position="right">
                <Button
                  as="a"
                  icon={"clipboard check"}
                  content={
                    serviceType === SERVICE_TYPE.CARNAWA
                      ? "최저가 확인하기"
                      : "숨은 보상금 찾기"
                  }
                  color={"orange"}
                  style={{
                    fontFamily: "paybooc-Light",
                    // background:'#fff',
                    // color:'#000'
                  }}
                  onClick={requestReport}
                />
              </Menu.Item>
            </Container>
          )}
        </Menu>
      </Transition>

      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          height: "100%",
          width: "100%",
          zIndex: -1,
          opacity: 0.35,
          overflow: "hidden",
        }}
      >
        <video
          ref={mediaRef}
          playsInline
          autoPlay
          muted
          loop
          style={{
            height: "100%",
            width: "100%",
            objectFit: "cover",
          }}
        >
          <source src={background} type="video/mp4" />
          Your browser is not supported!
        </video>
      </div>

      <Swiper
        onSwiper={(swiper) => {
          setCarnawaSwiper(swiper);
        }}
        className="swiper-v"
        speed={300}
        direction={"vertical"}
        pagination={{
          dynamicBullets: true,
          clickable: true,
        }}
        slidesPerView={1}
        mousewheel={{
          forceToAxis: true,
        }}
        modules={[Pagination, Mousewheel]}
        onSlideChange={(swiper) => {
          setCarnawaSwiper(swiper);
          setMenuVisible(swiper.slides.length - 1 !== swiper.realIndex);
        }}
      >
        {_.map(ContentGroup[serviceType], (content) => {
          if (
            content.hasOwnProperty("desktop") ||
            content.hasOwnProperty("mobile")
          ) {
            return isDesktop
              ? content.desktop
              : isMobile
              ? content.mobile
              : null;
          } else {
            return content;
          }
        })}
      </Swiper>

      <ModalLegalInformation {...legalInformation} />
      {isMobile && (
        <TransitionablePortal
          open={mobileMenuPortal.open}
          transition={{ animation: "slide down", duration: 500 }}
          onClose={mobileMenuPortal.close}
        >
          <Segment
            basic
            inverted
            style={{
              position: "fixed",
              left: 0,
              top: "48px",
              right: 0,
              bottom: 0,
              margin: 0,
              zIndex: 1000,
            }}
          >
            <Menu vertical inverted fluid>
              <Menu.Item
                as="a"
                active={serviceType === SERVICE_TYPE.ACCIDENT_HISTORY}
                style={{
                  background:
                    serviceType === SERVICE_TYPE.ACCIDENT_HISTORY
                      ? "#fff"
                      : null,
                }}
                onClick={() => {
                  changeService(SERVICE_TYPE.ACCIDENT_HISTORY);
                  mobileMenuPortal.close();
                }}
              >
                <Header
                  inverted={serviceType !== SERVICE_TYPE.ACCIDENT_HISTORY}
                  style={{ fontFamily: "paybooc-Light" }}
                >
                  숨은 보상금 찾기
                  <Header.Subheader style={{ color: "#888" }}>
                    사고이력 조회하고 보상금받아가세요.
                  </Header.Subheader>
                </Header>
              </Menu.Item>

              <Menu.Item
                as="a"
                active={serviceType === SERVICE_TYPE.CARNAWA}
                style={{
                  background:
                    serviceType === SERVICE_TYPE.CARNAWA ? "#fff" : null,
                }}
                onClick={() => {
                  changeService(SERVICE_TYPE.CARNAWA);
                  mobileMenuPortal.close();
                }}
              >
                <Header
                  inverted={serviceType !== SERVICE_TYPE.CARNAWA}
                  style={{ fontFamily: "paybooc-Light" }}
                >
                  중고차 가격비교
                  <Header.Subheader style={{ color: "#888" }}>
                    가장 싼 가격으로 구매하세요.
                  </Header.Subheader>
                </Header>
              </Menu.Item>

              <Menu.Item
                as="a"
                href={process.env.REACT_APP_CARMON_SITE_URL}
                target={"_blank"}
                onClick={mobileMenuPortal.close}
              >
                <Header inverted style={{ fontFamily: "paybooc-Light" }}>
                  내차 팔기
                  <Header.Subheader style={{ color: "#888" }}>
                    정말 제값받는 내차팔기 서비스 카몬!
                  </Header.Subheader>
                </Header>
              </Menu.Item>
            </Menu>
          </Segment>
        </TransitionablePortal>
      )}
    </>
  );
};

export default Home;
