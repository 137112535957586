import React, {useLayoutEffect, useState} from 'react';
import {Button, Container, Form, Header, Input, Modal, Segment} from 'semantic-ui-react';
import axios from 'axios';
import {useSearchParams} from 'react-router-dom';
import _ from 'lodash';
import {CommonUtil} from '../utils/CommonUtil';

function SendReport() {
  const [searchParams] = useSearchParams();
  const req = searchParams.get('req');

  const [showConfirm, setShowConfirm] = useState(false);
  const [carType, setCarType] = useState('');
  const [fromPrice, setFromPrice] = useState('0');
  const [toPrice, setToPrice] = useState('0');
  const [contact, setContact] = useState('');

  const [loading, setLoading] = useState(false);

  const sendReport = () => {
    setLoading(true);

    axios.post('/apis/carnawa/car-buy-request', {
      prev: req ? _.split(req, '|').join(', ') : '0',
      carType,
      carPriceRange: `${fromPrice}만원 ~ ${toPrice}만원`,
      contact,
    })
      .then(({data}) => {
        alert('문의가 접수되었습니다. 확인 후 연락드리겠습니다. 카몬을 이용해주셔서 감사합니다.');
        window.close();
      })
      .catch(({response: {data: errorData}}) => {

      })
      .finally(() => {
        setLoading(false);
      });
  };

  useLayoutEffect(() => {
    document.body.style.background = 'none';
  }, []);

  return (
    <div style={{padding: 20}}>
      <Container style={{width: 480}}>
        <Segment loading={loading}>
          <Header color="yellow" dividing>
            원하는 차량 문의하기
            <Header.Subheader>
              원하시는 차량을 문의하세요. 카몬에서 원하는 조건에 맞춰 고객님의 차량을 찾아드립니다.
            </Header.Subheader>
          </Header>
          <Form>
            <Form.Field>
              <label>원하시는 차종 또는 차량명</label>
              <Input placeholder="예) [경차, 세단, SUV, 트럭 등] 또는 [기아 K7, 현대 소나타 하이브리드 등]" onChange={(e, {value}) => {
                setCarType(value);
              }} />
            </Form.Field>
            <Form.Group widths="equal">
              <Form.Field>
                <label>원하시는 금액</label>
                <Input type="number" min="0" fluid placeholder="최소" label={{basic: true, content: '만원 부터'}}
                       labelPosition="right"
                       value={fromPrice}
                       onChange={(e, {value}) => {
                         setFromPrice(value.length > 6 ? '0' : value);
                       }} />
              </Form.Field>
              <Form.Field>
                <label>&nbsp;</label>
                <Input type="number" min="0" fluid placeholder="최대" label={{basic: true, content: '만원 까지'}}
                       labelPosition="right"
                       value={toPrice}
                       onChange={(e, {value}) => {
                         setToPrice(value.length > 6 ? '0' : value);
                       }} />
              </Form.Field>
            </Form.Group>
            <Form.Field>
              <label>연락처</label>
              <Input placeholder="카몬 고객센터에서 연락드립니다. 연락 가능한 전화번호를 입력하세요."
                     maxLength={13}
                     value={contact}
                     onChange={(e, {value}) => {
                       const trimmed = value.replace(/\s/g, '');
                       let text;
                       const masked = CommonUtil.CellPhone.format(trimmed);
                       text = masked;
                       setContact(text);
                     }} />
            </Form.Field>
            <Button loading={loading} icon="paper plane outline" color="yellow" content="문의하기"
                    disabled={_.isEmpty(_.trim(carType)) || (fromPrice === 0 || toPrice === 0) || _.isEmpty(contact)}
                    onClick={() => setShowConfirm(true)} />
            <Button content="취소" onClick={() => window.close()} />
          </Form>
        </Segment>
        <Modal
          centered={false}
          size="tiny"
          onClose={() => setShowConfirm(false)}
          onOpen={() => setShowConfirm(true)}
          open={showConfirm}>
          <Modal.Header>등록하기</Modal.Header>
          <Modal.Content image>
            <Modal.Description>
              <p>차량 구매 문의를 등록하시겠습니까?</p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button content="아니오" onClick={() => setShowConfirm(false)} />
            <Button icon="check" content="네" color="yellow"
                    onClick={() => {
                      setShowConfirm(false);
                      sendReport();
                    }} />
          </Modal.Actions>
        </Modal>
      </Container>
    </div>
  );
}

export default SendReport;
