export const BASE_STYLES = {
  THEME_COLOR: '#00aed9',
  THEME_SUB_COLOR: '#feb948',
  THEME_ITEM_COLOR: '#f0f0f0',
  THEME_BG_COLOR: '#696969',
  THEME_ATTENTION_COLOR: '#ae0000',
  FONT_COLOR: 'rgb(27, 28, 29)',
  BORDER_COLOR: 'rgba(34,36,38,.1)',
  FONT_SIZE: {
    HEADER: {
      MAIN: 36,
      SUB: 26,
    },
    MAX: 22,
    BASE: 18,
    SUB: 16,
    MIN: 14,
  },
  NAVBAR_HEIGHT: 66,
};

export const CARMON_INFO = {
  COMPANY_NAME: '주식회사 카몬스터',
  COMPANY_ENG_NAME: 'Carmonster Corp.',
  COMPANY_NAME_ABBREVIATION: '카몬',
  REPRESENTATIVE: '성백환',
  PERSONAL_INFO: '성백환',
  REPRESENTATIVE_TEL: '070-7576-4981',
  ADDRESS: '서울특별시 구로구 경인로 579, B동 308호',
  POSTAL_CODE: '08212',
  BUSINESS_NUMBER: '457-87-02409',
  SELLER_NUMBER: '제 2022-서울구로-0645 호',
  PROVIDE_ONLINE_CAR_INFORMATION: '제 11-1119-000001 호',
  CONTACT_EMAIL: 'carmonster000@gmail.com',
  SUPPORT_TEL: '070-7576-4981',
  SUPPORT_TIME: '평일 10:00 ~ 17:30',
};

export const SERVICE_TYPE = {
  CARNAWA: 'carnawa',
  ACCIDENT_HISTORY: 'repair'
}