import React from 'react';
import './App.css';
import {Route, Routes, Navigate} from 'react-router-dom';
import SendReport from './views/SendReport';
import SendRequest from './views/SendRequest';
import NotFound from './views/NotFound';
import Home from './views/landing/Home.js';
import RepairReq from './views/requests/RepairReq';
import CarnawaReq from './views/requests/CarnawaReq';

function App() {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Navigate to="/repair" replace/>}/>
        <Route exact path="/:service" element={<Home />} />
        <Route exact path="/request/carnawa" element={<CarnawaReq />} />
        <Route exact path="/request/repair" element={<RepairReq />} />
        <Route exact path="send-report" element={<SendReport />} />
        <Route exact path="send-request" element={<SendRequest />} />
        <Route path={'*'} element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
