import React, {useLayoutEffect, useState} from 'react';
import {Button, Container, Form, Header, Input, Modal, Segment} from 'semantic-ui-react';
import axios from 'axios';
import _ from 'lodash';
import {useSearchParams} from 'react-router-dom';

function SendReport() {
  const [searchParams] = useSearchParams();
  const req = searchParams.get('req');

  const [showConfirm, setShowConfirm] = useState(false);
  const [site, setSite] = useState(null);
  const [loading, setLoading] = useState(false);

  const sendReport = () => {
    setLoading(true);

    axios.post('/apis/carnawa/new-site-report', {
      prev: req ? _.split(req, '|').join(', ') : '0',
      site,
    })
      .then(({data}) => {
        alert('제보가 접수되었습니다. 확인 후, 빠르게 서비스에 반영하도록 하겠습니다. 감사합니다.');
        window.close();
      })
      .catch(({response: {data: errorData}}) => {

      })
      .finally(() => {
        setLoading(false);
      });
  };

  useLayoutEffect(() => {
    document.body.style.background = 'none';
  }, []);

  return (
    <div style={{padding: 20}}>
      <Container style={{width: 480}}>
        <Segment loading={loading}>
          <Header color="yellow" dividing>
            중고차 매매 사이트 제보하기
            <Header.Subheader>
              고객님이 알고 계시는 중고차 매매 사이트를 알려주세요.<br />정확하고 상세한 가격비교 서비스를 제공하는데 도움이 됩니다.
            </Header.Subheader>
          </Header>
          <Form>
            <Form.Field>
              <label>매매사이트</label>
              <Input placeholder="매매사이트 이름 또는 URL" value={site} onChange={(e, {value}) => {
                setSite(value);
              }} />
            </Form.Field>
            <Button loading={loading} icon="paper plane outline" color="yellow" content="제보하기"
                    disabled={_.isEmpty(_.trim(site))}
                    onClick={() => setShowConfirm(true)} />
            <Button content="취소" onClick={() => window.close()} />
          </Form>
        </Segment>
        <Modal
          centered={false}
          size="tiny"
          onClose={() => setShowConfirm(false)}
          onOpen={() => setShowConfirm(true)}
          open={showConfirm}>
          <Modal.Header>제보하기</Modal.Header>
          <Modal.Content image>
            <Modal.Description>
              <p>해당 사이트를 제보하시겠습니까?</p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button content="아니오" onClick={() => setShowConfirm(false)} />
            <Button icon="check" content="네" color="yellow"
                    onClick={() => {
                      setShowConfirm(false);
                      sendReport();
                    }} />
          </Modal.Actions>
        </Modal>
      </Container>
    </div>
  );
}

export default SendReport;
