import * as ReactGA from "react-ga";

import {
  Button,
  Card,
  Container,
  Divider,
  Form,
  Grid,
  Header,
  HeaderSubheader,
  Icon,
  Input,
  Item,
  Label,
  Message,
  Modal,
  Popup,
  Segment,
  Table,
  Transition,
} from "semantic-ui-react";
import React, { useRef, useState } from "react";
import { isDesktop, isMobile } from "react-device-detect";

import CarmonPrivacyPolicy from "../popup/PrivacyPolicy";
import { CommonUtil } from "../../utils/CommonUtil";
import _ from "lodash";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const CONTACT_TYPE = {
  EMAIL: "EML",
  CELLPHONE: "CEL",
};

const DEFAULT_PROGRESS_MESSAGE = {
  step1: {
    state: null,
    inProgress: "차량 정보를 확인중입니다.",
    complete: "차량이 확인되었습니다!",
    errorMessage: "",
  },
  step2: {
    state: null,
    inProgress: "정비이력을 조회하고있습니다.",
    complete:
      "#count# 건의 정비이력이 조회되었습니다. (일반 정비내용이 포함되어있으며, 사고와 무관한 데이터일수 있습니다.)",
  },
  step3: {
    state: null,
    inProgress: "리포트를 받아보실 연락처를 입력해주세요.(알림톡 발송)",
    complete: (
      <>
        리포트 신청이 완료되었습니다.
        <br />
        요청하신 연락처로 정비이력 리포트가 발송됩니다.
      </>
    ),
  },
};

const RepairReq = ({ close }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [carInformation, setCarInformation] = useState({});
  const [data, setData] = useState(null);
  const [, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [progressMessage, setProgressMessage] = useState(
    _.cloneDeep(DEFAULT_PROGRESS_MESSAGE)
  );
  const [wrongPhoneNumber, setWrongPhoneNumber] = useState(false);

  const [detailModels, setDetailModels] = useState(null);
  const [resDetail, setResDetail] = useState({ needDetail: false });

  const [form, setForm] = useState({
    licensePlateNumber: "",
    ownerName: "",
    purchasePrice: "",
  });

  const [formErrors, setFormErrors] = useState({
    licensePlateNumber: false,
    ownerName: false,
  });

  const [contact, setContact] = useState({
    contactText: "",
    contactType: CONTACT_TYPE.CELLPHONE,
  });

  const [, setMessage] = useState({
    visible: false,
    content: "",
  });

  const searchTextRef = useRef(null);
  const ownerNameRef = useRef(null);
  //const purchasePriceRef = useRef(null);

  const API = {
    getCarInformation: () => {
      let params = Object.assign({}, form);
      if (resDetail?.needDetail) _.assign(params, resDetail);
      params.purchasePrice = _.toNumber(params.purchasePrice) * 10000;
      return axios.post("/apis/carnawa/repair/information", params);
    },
    getRepairHistory: (repairHistoryId) => {
      const params = Object.assign({}, form, {
        repairHistoryId: repairHistoryId,
      });
      return axios.post("/apis/carnawa/repair/history", params);
    },
    requestReport: () => {
      const params = Object.assign({}, form, contact, carInformation);
      params.purchasePrice = _.toNumber(params.purchasePrice) * 10000;
      return axios.post("/apis/carnawa/repair/report", params);
    },
  };

  const getCarInformation = () => {
    API.getCarInformation()
      .then(({ data }) => {
        if (process.env.REACT_APP_RUN_MODE === "PROD") {
          ReactGA.event({
            category: "user-event",
            action: "reapir-history-car-validation",
            label: "사고이력리포트 소유자검증",
          });
        }

        if (_.get(data, "needDetail")) {
          setResDetail(data);
          setDetailModels(_.get(data, "models"));
          return;
        } else {
          setResDetail({ needDetail: false });
          setDetailModels(null);
        }

        setCarInformation(data);

        setProgressMessage((prevState) => {
          return {
            ...prevState,
            step1: Object.assign({}, prevState.step1, { state: "done" }),
            step2: Object.assign({}, prevState.step2, { state: "inProgress" }),
          };
        });

        //차량 정비이력 조회
        API.getRepairHistory(data.repairHistoryId)
          .then(({ data }) => {
            setData(data);
            setProgressMessage((prevState) => {
              return {
                ...prevState,
                step2: Object.assign({}, prevState.step2, {
                  state: "done",
                  complete: prevState.step2.complete.replace(
                    "#count#",
                    data.count
                  ),
                }),
                step3: Object.assign({}, prevState.step3, { state: "wait" }),
              };
            });
          })
          .catch((data) => {
            setProgressMessage((prevState) => {
              return {
                ...prevState,
                step2: Object.assign({}, prevState.step2, {
                  state: "error",
                  errorMessage:
                    (data.details && data.details[0]) || data.message,
                }),
              };
            });
          })
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((error) => {
        let data = error.response?.data;

        setProgressMessage((prevState) => {
          return {
            ...prevState,
            step1: Object.assign({}, prevState.step1, {
              state: "error",
              errorMessage:
                (data && ((data.details && data.details[0]) || data.message)) ||
                "서버로부터 응답을 받지 못했습니다. 잠시후에 다시 시도해주세요.",
            }),
          };
        });

        setLoading(false);
      });
  };

  const doRepairHistoryProcess = () => {
    if (_.isEmpty(form.ownerName)) {
      setErrorMessage("소유자명을 입력해주세요.");
      return;
    }

    setProgressMessage((prevState) => {
      return {
        ...prevState,
        step1: Object.assign({}, prevState.step1, { state: "inProgress" }),
      };
    });

    setLoading(true);
    setMessage({ visible: false, content: "" });

    getCarInformation();
  };

  const requestReport = () => {
    let isPhoneNumber = CommonUtil.Validator.validateCellPhone(
      contact.contactText
    );
    setWrongPhoneNumber(!isPhoneNumber);
    if (!isPhoneNumber) return;

    setProgressMessage((prevState) => {
      return {
        ...prevState,
        step3: Object.assign({}, prevState.step3, { state: "inProgress" }),
      };
    });

    API.requestReport()
      .then((res) => {
        if (process.env.REACT_APP_RUN_MODE === "PROD") {
          ReactGA.event({
            category: "user-event",
            action: "reapir-history-report-request",
            label: "사고이력 리포트 요청",
          });
        }

        setProgressMessage((prevState) => {
          return {
            ...prevState,
            step3: Object.assign({}, prevState.step3, { state: "done" }),
          };
        });

        setData(null);
      })
      .catch((error) => {
        let data = error.response?.data;

        setProgressMessage((prevState) => {
          return {
            ...prevState,
            step3: Object.assign({}, prevState.step3, {
              state: "error",
              errorMessage:
                (data && ((data.details && data.details[0]) || data.message)) ||
                "서버로부터 응답을 받지 못했습니다. 잠시후에 다시 시도해주세요.",
            }),
          };
        });
      });
  };

  const clear = () => {
    setForm({
      licensePlateNumber: "",
      ownerName: "",
      purchasePrice: "",
    });

    setFormErrors({
      licensePlateNumber: false,
      ownerName: false,
    });

    setContact({
      contactType: CONTACT_TYPE.CELLPHONE,
      contactText: "",
    });

    setProgressMessage(_.cloneDeep(DEFAULT_PROGRESS_MESSAGE));

    setLoading(false);
  };

  const closeProgressModal = () => {
    setResDetail({ needDetail: false });
    setDetailModels(null);

    setOpen(false);
    setData(null);
    clear();
  };

  return (
    <Container text style={{ margin: 0 }}>
      <Segment basic textAlign={"center"} style={{ position: "relative" }}>
        <Button
          size={"mini"}
          color={"black"}
          basic
          icon={"times"}
          style={{ position: "absolute", top: 7, right: 10, zIndex: 9 }}
          onClick={() => {
            navigate("/repair");
          }}
        />
        <Grid>
          <Grid.Row style={{ paddingBottom: 5 }}>
            <Grid.Column width={16} textAlign={"center"}>
              <Header
                style={{
                  padding: 0,
                  fontSize: isDesktop ? 25 : 20,
                  fontFamily: "GmarketSansMedium",
                  fontWeight: 600,
                  wordWrap: "break-word",
                  wordBreak: "keep-all",
                }}
              >
                카나와 숨은 보상금 찾기
              </Header>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Divider />

        <Grid
          stackable
          centered
          style={{
            height: "100%",
          }}
        >
          <Grid.Row>
            <Grid.Column width={16} textAlign={"left"} verticalAlign={"middle"}>
              <Container
                text
                className={"carmon-view-container"}
                textAlign={"center"}
              >
                <Container text>
                  <Form error={formErrors.searchText}>
                    <Grid stackable centered>
                      <Grid.Row centered stretched>
                        <Grid.Column width={12}>
                          <span
                            style={{
                              width: "100%",
                              textAlign: "center",
                              color: "#000",
                              marginBottom: "7px",
                              fontSize: isDesktop ? 20 : 11,
                            }}
                          >
                            소유하고 계시는 차량 번호를 입력해주세요.
                          </span>
                          <div ref={searchTextRef}>
                            <Form.Field
                              id="license-number-plate-text"
                              control={Input}
                              size="large"
                              className="license-number-plate-inner"
                              autoComplete="off"
                              maxLength="9"
                              disabled={loading || data !== null}
                              value={form.licensePlateNumber}
                              placeholder={"차량번호"}
                              onChange={(e, { value }) => {
                                const trimmed = value.replace(/\s/g, "");
                                let isValid =
                                  CommonUtil.Validator.validateLicensePlateNumber(
                                    trimmed
                                  );

                                setFormErrors((prevState) => ({
                                  ...prevState,
                                  licensePlateNumber:
                                    trimmed === "" ? false : !isValid,
                                }));
                                setForm((prevState) => ({
                                  ...prevState,
                                  licensePlateNumber: trimmed,
                                }));
                              }}
                              onKeyPress={(e) => {
                                if (e.key === "Enter") {
                                  e.preventDefault();

                                  if (
                                    form.licensePlateNumber !== "" &&
                                    !formErrors.licensePlateNumber
                                  ) {
                                    e.target.blur();
                                    ownerNameRef.current
                                      .querySelector("#owner-name")
                                      .focus();
                                  }
                                }
                              }}
                              error={formErrors.licensePlateNumber}
                            />
                          </div>
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column width={12}>
                          <div ref={ownerNameRef}>
                            <Form.Field
                              label={
                                <Popup
                                  basic
                                  position={"bottom left"}
                                  content={
                                    <span>
                                      차량의 상세한 정보를 확인하기 위해 차량의
                                      실제 소유자 정보가 필요합니다.
                                    </span>
                                  }
                                  trigger={
                                    <label
                                      style={{
                                        float: "left",
                                        color: "#000",
                                        fontSize: isDesktop ? "1.2rem" : "1rem",
                                      }}
                                    >
                                      차량 소유자명(필수){" "}
                                      <Icon
                                        color={"grey"}
                                        name={"info circle"}
                                      />
                                    </label>
                                  }
                                />
                              }
                              control={Input}
                              size={isDesktop ? "big" : "mini"}
                              placeholder={"소유자명"}
                              id="owner-name"
                              autoComplete="off"
                              icon={
                                <Icon
                                  name={"user"}
                                  size={isDesktop ? "big" : "large"}
                                />
                              }
                              iconPosition={"left"}
                              value={form.ownerName}
                              error={formErrors.ownerName}
                              disabled={loading || data !== null}
                              onChange={(e, { value }) => {
                                setForm((prevState) => ({
                                  ...prevState,
                                  ownerName: value,
                                }));
                              }}
                              onKeyPress={(e) => {
                                if (
                                  e.key === "Enter" &&
                                  form.ownerName !== "" &&
                                  !formErrors.ownerName
                                ) {
                                  //purchasePriceRef.current.querySelector("#purchase-price").focus();
                                  doRepairHistoryProcess();
                                }
                              }}
                            />
                          </div>
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column width={12}>
                          <Button
                            fluid
                            color={"black"}
                            icon="search"
                            content={"숨은 보상금 찾기"}
                            size={isDesktop ? "massive" : "big"}
                            disabled={
                              loading ||
                              form.licensePlateNumber === "" ||
                              formErrors.licensePlateNumber ||
                              form.ownerName === "" ||
                              formErrors.ownerName ||
                              data !== null
                            }
                            onClick={() => {
                              setFormErrors((prevState) => ({
                                ...prevState,
                                licensePlateNumber: false,
                                ownerName: false,
                              }));

                              doRepairHistoryProcess();
                            }}
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Form>
                </Container>
              </Container>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>

      <Segment textAlign={"left"} basic>
        {progressMessage.step1.state !== null && (
          <Transition
            visible={progressMessage.step1.state !== null}
            animation="fade"
            duration={1500}
          >
            <Segment>
              <Header as={"h3"}>
                <Header.Content>
                  <Icon
                    loading={progressMessage.step1.state === "inProgress"}
                    color={
                      progressMessage.step1.state === "inProgress"
                        ? "grey"
                        : progressMessage.step1.state === "error"
                        ? "red"
                        : "green"
                    }
                    name={
                      progressMessage.step1.state === "inProgress"
                        ? "spinner"
                        : progressMessage.step1.state === "error"
                        ? "ban"
                        : "check circle outline"
                    }
                  />
                  {progressMessage.step1.inProgress}
                </Header.Content>

                <Header.Subheader style={{ paddingLeft: 33 }} color={"orange"}>
                  {progressMessage.step1.state === "done"
                    ? progressMessage.step1.complete
                    : progressMessage.step1.errorMessage}
                </Header.Subheader>

                {progressMessage.step1.state === "done" ? (
                  <Header.Subheader
                    style={{ paddingTop: 0, paddingLeft: 33 }}
                    color={"orange"}
                  >
                    <Item.Group>
                      <Item>
                        <Item.Content>
                          <Item.Header>
                            <Header as={"h4"}>
                              <Header.Content>
                                <Header.Subheader>
                                  <br />
                                  <strong>
                                    [{carInformation?.base?.makername}]
                                  </strong>
                                </Header.Subheader>
                                {carInformation?.base?.modelname}{" "}
                                {carInformation?.base?.seriesname}
                              </Header.Content>
                            </Header>
                          </Item.Header>
                          <Item.Meta
                            style={{
                              wordWrap: "break-word",
                              wordBreak: "keep-all",
                            }}
                          >
                            <Table
                              compact
                              striped
                              stackable
                              celled
                              style={{ fontFamily: "paybooc-Light" }}
                            >
                              <Table.Body>
                                <Table.Row>
                                  <Table.Cell
                                    width={3}
                                    style={{ color: "#888" }}
                                  >
                                    연식
                                  </Table.Cell>
                                  <Table.Cell
                                    width={5}
                                  >{`${carInformation?.base?.year}년식`}</Table.Cell>
                                  <Table.Cell
                                    width={3}
                                    style={{ color: "#888" }}
                                  >
                                    최초등록일
                                  </Table.Cell>
                                  <Table.Cell width={5}>
                                    {moment(
                                      carInformation?.base?.firstdate
                                    ).format("YYYY년 MM월 DD일")}
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell style={{ color: "#888" }}>
                                    차종
                                  </Table.Cell>
                                  <Table.Cell>
                                    {carInformation?.base?.cargubun}
                                  </Table.Cell>
                                  <Table.Cell style={{ color: "#888" }}>
                                    용도
                                  </Table.Cell>
                                  <Table.Cell>
                                    {carInformation?.base?.usegubun}
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell style={{ color: "#888" }}>
                                    연료
                                  </Table.Cell>
                                  <Table.Cell>
                                    {carInformation?.base?.fuel}
                                  </Table.Cell>
                                  <Table.Cell style={{ color: "#888" }}>
                                    배기량
                                  </Table.Cell>
                                  <Table.Cell>
                                    {CommonUtil.Unit.format(
                                      carInformation?.base?.displacement
                                    )}{" "}
                                    cc
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell style={{ color: "#888" }}>
                                    색상
                                  </Table.Cell>
                                  <Table.Cell>
                                    {carInformation?.base?.color}
                                  </Table.Cell>
                                  <Table.Cell style={{ color: "#888" }}>
                                    정원
                                  </Table.Cell>
                                  <Table.Cell>
                                    {_.toNumber(carInformation?.base?.seating)}
                                    명
                                  </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                  <Table.Cell style={{ color: "#888" }}>
                                    변속기
                                  </Table.Cell>
                                  <Table.Cell>
                                    {carInformation?.base?.gearbox}
                                  </Table.Cell>
                                  <Table.Cell style={{ color: "#888" }}>
                                    연비
                                  </Table.Cell>
                                  <Table.Cell>
                                    {carInformation?.base?.stdmileage}
                                  </Table.Cell>
                                </Table.Row>
                              </Table.Body>
                            </Table>
                          </Item.Meta>
                          {carInformation?.description && (
                            <Item.Description
                              style={{
                                wordWrap: "break-word",
                                wordBreak: "keep-all",
                              }}
                            >
                              {carInformation?.description}
                            </Item.Description>
                          )}
                          {carInformation?.detail?.count === 1 && (
                            <Item.Extra>
                              {carInformation?.detail?.data?.carOpt
                                ?.split(/,/)
                                .map((keyword, idx) => (
                                  <Label
                                    size={isDesktop ? "small" : "tiny"}
                                    key={`keyword${idx}`}
                                    color="teal"
                                  >
                                    {keyword}
                                  </Label>
                                ))}
                            </Item.Extra>
                          )}
                        </Item.Content>
                      </Item>
                    </Item.Group>
                  </Header.Subheader>
                ) : (
                  ""
                )}
              </Header>
            </Segment>
          </Transition>
        )}

        {progressMessage.step2.state !== null && (
          <Transition
            visible={progressMessage.step2.state !== null}
            animation="fade"
            duration={1500}
          >
            <Segment>
              <Header as={"h3"}>
                <Header.Content>
                  <Icon
                    loading={progressMessage.step2.state === "inProgress"}
                    color={
                      progressMessage.step2.state === "inProgress"
                        ? "grey"
                        : progressMessage.step2.state === "error"
                        ? "red"
                        : "green"
                    }
                    name={
                      progressMessage.step2.state === "inProgress"
                        ? "spinner"
                        : progressMessage.step2.state === "error"
                        ? "ban"
                        : "check circle outline"
                    }
                  />
                  {progressMessage.step2.inProgress}
                </Header.Content>

                <Header.Subheader style={{ paddingLeft: 33 }}>
                  {progressMessage.step2.state === "done"
                    ? progressMessage.step2.complete
                    : progressMessage.step2.errorMessage}
                </Header.Subheader>
              </Header>
            </Segment>
          </Transition>
        )}

        {progressMessage.step3.state !== null && (
          <Transition
            visible={progressMessage.step3.state !== null}
            animation="fade"
            duration={1500}
          >
            <Segment>
              <Header as={"h3"}>
                <Header.Content>
                  <Icon
                    loading={progressMessage.step3.state === "inProgress"}
                    color={
                      progressMessage.step3.state === "inProgress" ||
                      progressMessage.step3.state === "wait"
                        ? "grey"
                        : progressMessage.step3.state === "error"
                        ? "red"
                        : "green"
                    }
                    name={
                      progressMessage.step3.state === "wait"
                        ? "edit outline"
                        : progressMessage.step3.state === "inProgress"
                        ? "spinner"
                        : progressMessage.step3.state === "error"
                        ? "ban"
                        : "check circle outline"
                    }
                  />
                  {progressMessage.step3.inProgress}
                </Header.Content>
                <Header.Subheader style={{ paddingLeft: 33 }}>
                  {progressMessage.step3.state === "done"
                    ? progressMessage.step3.complete
                    : progressMessage.step3.errorMessage}
                </Header.Subheader>
              </Header>

              <Input
                size={isDesktop ? "huge" : "large"}
                style={{ marginLeft: 10, marginBottom: 10 }}
                placeholder={"차량 구매가 (선택)"}
                fluid={true}
                value={form.purchasePrice}
                disabled={
                  progressMessage.step3.state === "inProgress" ||
                  progressMessage.step3.state === "done"
                }
                onChange={(e, { value }) => {
                  setForm((prevState) => ({
                    ...prevState,
                    purchasePrice: value,
                  }));
                }}
                action={
                  <CarmonPrivacyPolicy
                    linkTitle={isMobile ? "이용 약관" : "이용 약관 보기"}
                    style={{ color: "#fff", background: "#000" }}
                  />
                }
              />

              <Input
                size={isDesktop ? "huge" : "large"}
                style={{ marginLeft: 10, fontFamily: "paybooc-Light" }}
                placeholder={"휴대폰 번호"}
                fluid={true}
                value={contact.contactText}
                disabled={
                  progressMessage.step3.state === "inProgress" ||
                  progressMessage.step3.state === "done"
                }
                onChange={(e, { value }) => {
                  setContact((prevState) => ({
                    ...prevState,
                    contactText: value,
                    contactType: CONTACT_TYPE.CELLPHONE,
                  }));
                }}
                action={{
                  size: isDesktop ? "large" : "small",
                  icon: "file alternate",
                  content: "리포트요청",
                  color: "blue",
                  disabled:
                    progressMessage.step3.state === "inProgress" ||
                    progressMessage.step3.state === "done",
                  onClick: requestReport,
                }}
              />

              {wrongPhoneNumber && (
                <Label basic color={"red"} pointing>
                  올바른 휴대폰 번호를 입력해주세요.
                </Label>
              )}
            </Segment>
          </Transition>
        )}

        {errorMessage && (
          <Modal size="large" basic open={!_.isEmpty(errorMessage)}>
            <Message
              error
              size={isDesktop ? "large" : "small"}
              icon="exclamation triangle"
              header={
                <div>
                  <h3 style={{ float: "left" }}>오류</h3>
                  <span style={{ float: "right" }}>
                    <Icon
                      name={"times"}
                      onClick={() => setErrorMessage(null)}
                    />
                  </span>
                </div>
              }
              content={<div style={{ clear: "both" }}>{errorMessage}</div>}
            />
          </Modal>
        )}

        <Modal
          size="large"
          centered={false}
          open={resDetail?.needDetail}
          onClose={closeProgressModal}
          closeIcon
        >
          <Modal.Content>
            <Header>
              차량이 확인되었으나, 추가확인이 필요합니다.
              <HeaderSubheader>
                아래에서 해당하는 모델을 선택해주세요.
              </HeaderSubheader>
            </Header>
            <Divider />
            {_.map(detailModels, (model) => {
              return (
                <Card.Group>
                  {_.map(model["serieslist"], (series, idx) => {
                    return (
                      <Card
                        style={{
                          background:
                            series.seriesno === resDetail?.selectedSeries
                              ? "#00b5ad"
                              : null,
                        }}
                        onClick={() => {
                          setResDetail((prevState) => {
                            return {
                              ...prevState,
                              selectedSeries: series.seriesno,
                            };
                          });
                        }}
                      >
                        <Card.Content>
                          <Card.Header
                            style={{
                              color:
                                series.seriesno === resDetail?.selectedSeries
                                  ? "#ffffff"
                                  : null,
                            }}
                          >
                            {model.modelname}
                          </Card.Header>
                          <Card.Description
                            style={{
                              color:
                                series.seriesno === resDetail?.selectedSeries
                                  ? "#ffffff"
                                  : null,
                            }}
                          >
                            {series.seriesname}
                          </Card.Description>
                        </Card.Content>
                      </Card>
                    );
                  })}
                </Card.Group>
              );
            })}
          </Modal.Content>
        </Modal>
      </Segment>
    </Container>
  );
};

export default RepairReq;
