export const CommonUtil = {
  Unit: {
    format(unit) {
      const formatter = new Intl.NumberFormat('ko-KR');

      return formatter.format(unit ? Number(unit) : 0);
    },
  },
  Validator: {
    validatePassword(password = '') {
      const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/;
      return password !== undefined && password.trim() !== '' && regex.test(password);
    },
    validateCellPhone(phoneNumber = '') {
      const regex = /^01([016789])-?([0-9]{3,4})-?([0-9]{4})$/;
      return phoneNumber !== undefined && phoneNumber.trim() !== '' && regex.test(phoneNumber);
    },
    validateLicensePlateNumber(licensePlateNumber = '') {
      if (licensePlateNumber.length > 9) return false;

      if (licensePlateNumber.length === 8 && /^[가-힣]{2}\d{1}[가-힣]\d{4}/.test(licensePlateNumber)) return true;
      if (licensePlateNumber.length === 9 && /^[가-힣]{2}\d{2}[가-힣]\d{4}/.test(licensePlateNumber)) return true;
      if (licensePlateNumber.length === 7 && /^\d{2}[가-힣]\d{4}/.test(licensePlateNumber)) return true;
      if (licensePlateNumber.length === 8 && /^\d{3}[가-힣]\d{4}/.test(licensePlateNumber)) return true;

      return false;
    },
    validateYoutubeURL(youtubeURL = '') {
      const regex = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/;
      return youtubeURL !== '' && regex.test(youtubeURL);
    },
    validateEmailAddress(emailAddress = '') {
      const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,4})+$/;
      return emailAddress !== '' && regex.test(emailAddress);
    },
  },
  CellPhone: {
    format(phoneNumber = '') {
      let number = phoneNumber.replace(/[^0-9]/g, '');
      let length = number.length;
      let result = '';

      if (length < 4) return number;
      else if (length < 7) {
        result += number.substr(0, 3);
        result += '-';
        result += number.substr(3);
      } else if (length < 11) {
        result += number.substr(0, 3);
        result += '-';
        result += number.substr(3, 3);
        result += '-';
        result += number.substr(6);
      } else {
        result += number.substr(0, 3);
        result += '-';
        result += number.substr(3, 4);
        result += '-';
        result += number.substr(7);
      }

      return result;
    },
  },
};
