import "../../configs/axiosConfig";

import * as ReactGA from "react-ga";

import {
  Button,
  Container,
  Divider,
  Form,
  Grid,
  Header,
  Icon,
  Input,
  Item,
  Label,
  List,
  Message,
  Modal,
  Segment,
  Table,
} from "semantic-ui-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { isDesktop, isMobile } from "react-device-detect";

import CarmonPrivacyPolicy from "../popup/PrivacyPolicy";
import { CommonUtil } from "../../utils/CommonUtil";
import _ from "lodash";
import axios from "axios";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";

const SEARCH_TYPE = {
  LICENSE_PLATE_NUMBER: "LPN",
  YOUTUBE: "YTB",
};

const CONTACT_TYPE = {
  EMAIL: "EML",
  CELLPHONE: "CEL",
};

const MAX_RETRY_COUNT = 3;

const CarnawaReq = () => {
  const navigate = useNavigate();
  const [form, setForm] = useState({
    searchType: SEARCH_TYPE.LICENSE_PLATE_NUMBER,
    searchText: "",
    location: "구매 희망 지역",
  });
  const [formErrors, setFormErrors] = useState({
    searchText: false,
    licensePlateNumber: false,
  });

  const [targetCount, setTargetCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadStep, setLoadStep] = useState(-1);
  const [data, setData] = useState(null);

  const [requestResult, setRequestResult] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [doClose, setDoClose] = useState(false);

  const [notFound, setNotFound] = useState(false);
  const [contact, setContact] = useState({
    contactType: CONTACT_TYPE.CELLPHONE,
    contactText: "",
  });
  const [contactErrors, setContactErrors] = useState({
    contactText: false,
  });

  const searchTextRef = useRef(null);
  const [showSearchTextError, setShowSearchTextError] = useState(false);
  const contactTextRef = useRef(null);
  const [showContactTextError, setShowContactTextError] = useState(false);

  let timer = useRef(null);
  let currentRetryCount = useRef(0);

  useEffect(() => getTargetsCount(), []);

  const getTargetsCount = () => {
    axios.get("/apis/carnawa/info").then(({ data }) => setTargetCount(data));
  };

  const resetAll = () => {
    setData(null);
    setForm({
      searchType: SEARCH_TYPE.LICENSE_PLATE_NUMBER,
      searchText: "",
      location: "구매 희망 지역",
    });
    setFormErrors({
      searchText: false,
    });
    setContact({
      contactType: CONTACT_TYPE.EMAIL,
      contactText: "",
    });
    setContactErrors({
      contactText: false,
    });
    setNotFound(null);
    setShowSearchTextError(false);
    setShowContactTextError(false);
    setTimeout(() => searchTextRef.current.querySelector("input").focus(), 100);
  };

  const doValidation = useCallback(() => {
    setLoading(true);
    setLoadStep(0);
    timer.current = setInterval(
      () => setLoadStep((prevState) => prevState + 1),
      _.random(400, 800)
    );

    setTimeout(() => {
      axios
        .post("/apis/carnawa/validation", form)
        .then(({ data }) => {
          if (process.env.REACT_APP_RUN_MODE === "PROD") {
            ReactGA.event({
              category: "user-event",
              action: "car-validation",
              label: "차량확인",
            });
          }

          setData(data);
          setTimeout(
            () => contactTextRef.current.querySelector("input").focus(),
            100
          );
          currentRetryCount.current = 0;
        })
        .catch(({ response: { status, data } }) => {
          if (status === 404) {
            setNotFound(true);
          }
          if (status === 412) {
            if (currentRetryCount.current < MAX_RETRY_COUNT) {
              currentRetryCount.current++;
              doValidation();
            }
          }
        })
        .finally(() => {
          currentRetryCount.current === 0 && setLoading(false);
          if (timer.current) {
            clearInterval(timer.current);
            timer.current = null;
            setLoadStep(-1);
          }
        });
    }, 600);
  }, [form]);

  const requestReport = useCallback(() => {
    setLoading(true);

    const carData = _.cloneDeep(data);
    _.unset(carData, "licensePlateNumber");
    _.unset(carData, "searchType");

    axios
      .post("/apis/carnawa/report-request", {
        searchType: form.searchType,
        searchText: form.searchText,
        contactType: contact.contactType,
        contactText: contact.contactText,
        location: form.location,
        carData,
      })
      .then(({ data }) => {
        if (process.env.REACT_APP_RUN_MODE === "PROD") {
          ReactGA.event({
            category: "user-event",
            action: "report-request",
            label: "리포트 요청",
          });
        }

        setRequestResult(true);
        resetAll();
      })
      .catch(({ errorData }) => {
        setRequestResult(false);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [
    contact.contactText,
    contact.contactType,
    data,
    form.searchText,
    form.searchType,
    form.location,
  ]);

  return (
    <Container text style={{ margin: 0 }}>
      <Segment basic textAlign={"center"} style={{ position: "relative" }}>
        <Button
          size={"mini"}
          color={"black"}
          basic
          icon={"times"}
          style={{ position: "absolute", top: 7, right: 10, zIndex: 9 }}
          onClick={() => {
            navigate("/carnawa");
          }}
        />
        <Grid>
          <Grid.Row style={{ paddingBottom: 5 }}>
            <Grid.Column width={16} textAlign={"center"}>
              <Header
                style={{
                  padding: 0,
                  fontSize: isDesktop ? 25 : 20,
                  fontFamily: "GmarketSansMedium",
                  fontWeight: 600,
                  wordWrap: "break-word",
                  wordBreak: "keep-all",
                }}
              >
                카나와 중고차 가격비교
              </Header>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <Divider />

        <Grid stackable centered style={{ height: "100%" }}>
          <Grid.Row>
            <Grid.Column
              width={16}
              textAlign={"center"}
              verticalAlign={"middle"}
            >
              <Container text>
                <Form error={formErrors.searchText}>
                  <Grid stackable centered>
                    <Grid.Row centered stretched>
                      <Grid.Column width={12}>
                        <span
                          style={{
                            width: "100%",
                            textAlign: "center",
                            color: "#000",
                            marginBottom: "7px",
                            fontSize: isDesktop ? 20 : 11,
                          }}
                        >
                          가격 비교를 희망하는 차량 번호를 입력해주세요.
                        </span>

                        <div ref={searchTextRef}>
                          <Form.Field
                            id="license-number-plate-text"
                            control={Input}
                            size="large"
                            className="license-number-plate-inner"
                            autoComplete="off"
                            maxLength="9"
                            disabled={loading || data !== null || notFound}
                            value={form.searchText}
                            placeholder={
                              form.searchType ===
                              SEARCH_TYPE.LICENSE_PLATE_NUMBER
                                ? "차량번호"
                                : "Youtube 영상 주소 (https 포함)"
                            }
                            onChange={(e, { value }) => {
                              setShowSearchTextError(false);

                              const trimmed = value.replace(/\s/g, "");
                              let isValid = false;
                              if (
                                form.searchType ===
                                SEARCH_TYPE.LICENSE_PLATE_NUMBER
                              ) {
                                isValid =
                                  CommonUtil.Validator.validateLicensePlateNumber(
                                    trimmed
                                  );
                              } else if (
                                form.searchType === SEARCH_TYPE.YOUTUBE
                              ) {
                                isValid =
                                  CommonUtil.Validator.validateYoutubeURL(
                                    trimmed
                                  );
                              }

                              setFormErrors((prevState) => ({
                                ...prevState,
                                searchText: trimmed === "" ? false : !isValid,
                              }));
                              setForm((prevState) => ({
                                ...prevState,
                                searchText: trimmed,
                              }));
                            }}
                            onKeyPress={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault();
                                e.stopPropagation();

                                if (
                                  form.searchText !== "" &&
                                  !formErrors.searchText
                                ) {
                                  e.target.blur();
                                  doValidation();
                                }
                              }
                            }}
                            error={formErrors.searchText}
                          />
                        </div>
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Column width={8}>
                        <Button
                          fluid
                          color={"black"}
                          icon="search"
                          content={"중고차 가격비교"}
                          size={isDesktop ? "massive" : "big"}
                          disabled={
                            loading ||
                            form.searchText === "" ||
                            formErrors.searchText ||
                            data !== null
                          }
                          onClick={() => {
                            setShowSearchTextError(false);
                            setFormErrors((prevState) => ({
                              ...prevState,
                              searchText: false,
                            }));

                            doValidation();
                          }}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Form>

                {loading && loadStep >= 0 && (
                  // <Modal size="large" basic open={(loading && loadStep >= 0)}>
                  <Message
                    size={isDesktop ? "large" : "small"}
                    icon={isDesktop}
                    style={{ textAlign: "left" }}
                  >
                    {isDesktop && (
                      <Icon name="circle notched" loading={loading} />
                    )}
                    <Message.Content
                      style={{
                        fontSize: isDesktop ? 15 : 12,
                        fontFamily: "paybooc-Light",
                      }}
                    >
                      <Message.Header style={{ fontFamily: "paybooc-Light" }}>
                        {isMobile && (
                          <Icon name="circle notched" loading={loading} />
                        )}
                        고객님의 차량을 확인하고 있습니다!
                      </Message.Header>
                      <List bulleted>
                        {loadStep >= 0 && (
                          <List.Item content="차량이 실매물인지 확인하고 있습니다..." />
                        )}
                        {loadStep >= 1 && (
                          <List.Item content="차량의 정보를 확인하고 있습니다..." />
                        )}
                        {loadStep >= 2 && (
                          <List.Item content="차량 판매 사이트에서 중개 수수료, 보증여부 등을 확인하고 있습니다." />
                        )}
                      </List>
                    </Message.Content>
                  </Message>
                  // </Modal>
                )}

                {showSearchTextError && (
                  <Modal size="large" basic open={showSearchTextError}>
                    <Message
                      size={isDesktop ? "large" : "small"}
                      error
                      icon="exclamation triangle"
                      header={
                        form.searchType === SEARCH_TYPE.LICENSE_PLATE_NUMBER
                          ? "차량번호가 잘못되었습니다."
                          : "Youtube 주소가 잘못되었습니다."
                      }
                      content={
                        form.searchType === SEARCH_TYPE.LICENSE_PLATE_NUMBER
                          ? "차량번호를 정확히 입력해주세요."
                          : "Youtube 주소를 https를 포함하여 정확히 입력해주세요."
                      }
                    />
                  </Modal>
                )}

                {notFound && (
                  <Modal
                    size="large"
                    basic
                    open={notFound}
                    onClose={() => setNotFound(false)}
                    closeOnDimmerClick={true}
                    closeOnEscape={true}
                    closeOnDocumentClick={true}
                  >
                    <Message
                      size={isDesktop ? "large" : "small"}
                      error
                      icon="exclamation triangle"
                      header={
                        <>
                          <Header floated={"left"}>
                            차량 정보를 확인하지 못했습니다.
                          </Header>
                          <Icon
                            style={{ float: "right" }}
                            name={"times"}
                            onClick={() => setNotFound(false)}
                          />
                          <div style={{ clear: "both" }}></div>
                        </>
                      }
                      content={
                        "해당 차량은 매매용 차량이 아니거나, 매매사업조합에서 제시 ∙ 매도정보를 제공하지 않는 차량일 수 있습니다."
                      }
                    />
                  </Modal>
                )}

                {/*<Modal size="large" basic open={data !== null}>*/}
                {data !== null && (
                  <>
                    <Message
                      size={isDesktop ? "large" : "small"}
                      icon={isDesktop}
                    >
                      {isDesktop && <Icon name="chart bar outline" />}
                      <Message.Content
                        style={{
                          fontSize: isDesktop ? 15 : 12,
                          textAlign: "left",
                          fontFamily: "paybooc-Light",
                        }}
                      >
                        <Message.Header
                          style={{
                            wordBreak: "keep-all",
                            fontFamily: "paybooc-Light",
                          }}
                        >
                          {isMobile && <Icon name="chart bar outline" />}
                          카나와에서는 가격비교 시스템을 통해 고객님이 선택하신
                          차량을 전국 {targetCount}개 중고차 판매 사이트와 비교,
                          분석합니다. 비교 분석 결과는 카나와 컨설턴트가 최종
                          검수한 후 가격 비교 리포트를 보내드립니다.
                        </Message.Header>
                      </Message.Content>
                    </Message>

                    {data?.searchType &&
                      (data?.searchType === SEARCH_TYPE.LICENSE_PLATE_NUMBER ? (
                        <Segment textAlign={"left"}>
                          <Header
                            size={isDesktop ? "large" : "small"}
                            dividing
                            color="black"
                          >
                            <Icon name="car" />
                            <Header.Content>
                              차량확인결과
                              <Header.Subheader
                                style={{ fontSize: isDesktop ? 15 : 10 }}
                              >
                                <b>
                                  정상 매물로 등록된 차량으로 확인되었습니다!
                                </b>
                              </Header.Subheader>
                            </Header.Content>
                          </Header>
                          <Item.Group>
                            <Item>
                              {/*<Item.Image size="large" src={data?.imageSrc} />*/}
                              <Item.Content>
                                <Item.Header>
                                  <Header as={"h3"}>
                                    <Header.Content>
                                      {data?.base.carName ||
                                        data?.base.carDisplayName}
                                      <Header.Subheader>
                                        {data?.detail.count === 1 &&
                                          `${data?.detail.data.carNameDtl}`}
                                        {data?.detail.count === 1 &&
                                          ` (${data?.detail.data.carMaker})`}
                                      </Header.Subheader>
                                    </Header.Content>
                                  </Header>
                                </Item.Header>
                                <Item.Meta
                                  style={{
                                    wordWrap: "break-word",
                                    wordBreak: "keep-all",
                                  }}
                                >
                                  <Table compact striped stackable>
                                    <Table.Header />
                                    <Table.Body>
                                      <Table.Row>
                                        <Table.Cell width={3}>
                                          <Header
                                            as="h5"
                                            style={{
                                              color: "#888",
                                              fontFamily: "paybooc-Light",
                                            }}
                                          >
                                            연식
                                          </Header>
                                        </Table.Cell>
                                        <Table.Cell width={5}>
                                          {data?.base.carYear
                                            ? `${data?.base.carYear}년식`
                                            : data?.detail.count === 1
                                            ? `${data?.detail.data.prodYearName}년식`
                                            : "정보없음"}
                                        </Table.Cell>
                                        <Table.Cell width={3}>
                                          <Header
                                            as="h5"
                                            style={{
                                              color: "#888",
                                              fontFamily: "paybooc-Light",
                                            }}
                                          >
                                            최초등록일
                                          </Header>
                                        </Table.Cell>
                                        <Table.Cell width={5}>
                                          {moment(
                                            data?.base.carFirstDate
                                          ).format("YYYY년 MM월 DD일")}
                                        </Table.Cell>
                                      </Table.Row>
                                      <Table.Row>
                                        <Table.Cell>
                                          <Header
                                            as="h5"
                                            style={{
                                              color: "#888",
                                              fontFamily: "paybooc-Light",
                                            }}
                                          >
                                            차종
                                          </Header>
                                        </Table.Cell>
                                        <Table.Cell>
                                          {data?.base.carModel}
                                        </Table.Cell>
                                        <Table.Cell>
                                          <Header
                                            as="h5"
                                            style={{
                                              color: "#888",
                                              fontFamily: "paybooc-Light",
                                            }}
                                          >
                                            용도
                                          </Header>
                                        </Table.Cell>
                                        <Table.Cell>
                                          {data?.base.carUse}
                                        </Table.Cell>
                                      </Table.Row>
                                      <Table.Row>
                                        <Table.Cell>
                                          <Header
                                            as="h5"
                                            style={{
                                              color: "#888",
                                              fontFamily: "paybooc-Light",
                                            }}
                                          >
                                            연료
                                          </Header>
                                        </Table.Cell>
                                        <Table.Cell>
                                          {data?.base.carFuel}
                                        </Table.Cell>
                                        <Table.Cell>
                                          <Header
                                            as="h5"
                                            style={{
                                              color: "#888",
                                              fontFamily: "paybooc-Light",
                                            }}
                                          >
                                            배기량
                                          </Header>
                                        </Table.Cell>
                                        <Table.Cell>
                                          {CommonUtil.Unit.format(
                                            data?.base.carDisplacement
                                          )}{" "}
                                          cc
                                        </Table.Cell>
                                      </Table.Row>
                                      <Table.Row>
                                        <Table.Cell>
                                          <Header
                                            as="h5"
                                            style={{
                                              color: "#888",
                                              fontFamily: "paybooc-Light",
                                            }}
                                          >
                                            색상
                                          </Header>
                                        </Table.Cell>
                                        <Table.Cell>
                                          {data?.base.carColor}
                                        </Table.Cell>
                                        <Table.Cell>
                                          <Header
                                            as="h5"
                                            style={{
                                              color: "#888",
                                              fontFamily: "paybooc-Light",
                                            }}
                                          >
                                            정원
                                          </Header>
                                        </Table.Cell>
                                        <Table.Cell>
                                          {_.toNumber(data?.base.carPerson)}명
                                        </Table.Cell>
                                      </Table.Row>
                                      <Table.Row>
                                        <Table.Cell>
                                          <Header
                                            as="h5"
                                            style={{
                                              color: "#888",
                                              fontFamily: "paybooc-Light",
                                            }}
                                          >
                                            변속기
                                          </Header>
                                        </Table.Cell>
                                        <Table.Cell>
                                          {data?.base.carTransmission}
                                        </Table.Cell>
                                        <Table.Cell>
                                          <Header
                                            as="h5"
                                            style={{
                                              color: "#888",
                                              fontFamily: "paybooc-Light",
                                            }}
                                          >
                                            주행거리
                                          </Header>
                                        </Table.Cell>
                                        <Table.Cell>
                                          {CommonUtil.Unit.format(
                                            data?.base.carMileage
                                          )}{" "}
                                          km
                                        </Table.Cell>
                                      </Table.Row>
                                    </Table.Body>
                                  </Table>
                                </Item.Meta>
                                {data?.description && (
                                  <Item.Description
                                    style={{
                                      wordWrap: "break-word",
                                      wordBreak: "keep-all",
                                    }}
                                  >
                                    {data?.description}
                                  </Item.Description>
                                )}
                                {data?.detail.count === 1 && (
                                  <Item.Extra>
                                    {data?.detail?.data?.carOpt
                                      ?.split(/,/)
                                      .map((keyword, idx) => (
                                        <Label
                                          key={`keyword${idx}`}
                                          color="black"
                                        >
                                          {keyword}
                                        </Label>
                                      ))}
                                  </Item.Extra>
                                )}
                              </Item.Content>
                            </Item>
                          </Item.Group>
                        </Segment>
                      ) : (
                        <Segment>
                          <Header
                            size={isDesktop ? "large" : "small"}
                            dividing
                            color="black"
                          >
                            <Icon name="youtube" />
                            Youtube 확인결과
                          </Header>
                          <Item.Group>
                            <Item>
                              <Item.Image size="large" src={data?.imageSrc} />
                              <Item.Content>
                                <Item.Header
                                  as="a"
                                  href={data?.youtubeURL}
                                  target="_blank"
                                >
                                  {data?.title}
                                </Item.Header>
                                <Item.Description
                                  style={{
                                    wordWrap: "break-word",
                                    wordBreak: "keep-all",
                                  }}
                                >
                                  {data?.description}
                                </Item.Description>
                              </Item.Content>
                            </Item>
                          </Item.Group>
                        </Segment>
                      ))}

                    <Segment textAlign={"left"}>
                      <Header
                        size={isDesktop ? "large" : "small"}
                        dividing
                        color="black"
                      >
                        {isDesktop && (
                          <CarmonPrivacyPolicy
                            linkTitle="이용 약관 보기"
                            style={{
                              float: "right",
                              color: "#fff",
                              background: "#000",
                            }}
                          />
                        )}
                        <Icon name="file text" />
                        <Header.Content>
                          카나와 리포트 수신방법
                          <Header.Subheader
                            style={{
                              fontSize: isDesktop ? 15 : 10,
                              color: "#888",
                            }}
                          >
                            <b>차량정보 및 가격비교 리포트를 보내드립니다!</b>
                          </Header.Subheader>
                        </Header.Content>
                      </Header>

                      <Form error={contactErrors.contactText}>
                        <Grid stackable centered>
                          <Grid.Row columns={"equal"} centered stretched>
                            <Grid.Column width={5}>
                              <Button.Group size={"small"}>
                                <Button
                                  {...(contact.contactType ===
                                    CONTACT_TYPE.EMAIL && { color: "green" })}
                                  disabled={loading}
                                  active={
                                    contact.contactType === CONTACT_TYPE.EMAIL
                                  }
                                  onClick={() => {
                                    setShowContactTextError(false);
                                    setContact({
                                      contactType: CONTACT_TYPE.EMAIL,
                                      contactText: "",
                                    });
                                    contactTextRef.current
                                      .querySelector("input")
                                      .focus();
                                  }}
                                >
                                  <Icon name="mail" />
                                  이메일
                                </Button>
                                <Button.Or />
                                <Button
                                  {...(contact.contactType ===
                                    CONTACT_TYPE.CELLPHONE && {
                                    color: "green",
                                  })}
                                  disabled={loading}
                                  active={
                                    contact.contactType ===
                                    CONTACT_TYPE.CELLPHONE
                                  }
                                  onClick={() => {
                                    setShowContactTextError(false);
                                    setContact({
                                      contactType: CONTACT_TYPE.CELLPHONE,
                                      contactText: "",
                                    });
                                    contactTextRef.current
                                      .querySelector("input")
                                      .focus();
                                  }}
                                >
                                  <Icon name="mobile alternate" />
                                  휴대전화
                                </Button>
                              </Button.Group>
                            </Grid.Column>

                            <Grid.Column>
                              <div ref={contactTextRef}>
                                <Form.Field
                                  id="contactText"
                                  control={Input}
                                  size="large"
                                  icon={
                                    contact.contactText !== "" && (
                                      <Icon
                                        name="remove"
                                        link
                                        onClick={() => {
                                          setShowContactTextError(false);
                                          setContactErrors((prevState) => ({
                                            ...prevState,
                                            contactText: false,
                                          }));
                                          setContact((prevState) => ({
                                            ...prevState,
                                            contactText: "",
                                          }));
                                        }}
                                      />
                                    )
                                  }
                                  value={contact.contactText}
                                  placeholder={
                                    contact.contactType === CONTACT_TYPE.EMAIL
                                      ? "이메일주소"
                                      : "휴대전화번호"
                                  }
                                  maxLength={
                                    contact.contactType === CONTACT_TYPE.EMAIL
                                      ? 200
                                      : 13
                                  }
                                  onChange={(e, { value }) => {
                                    setShowContactTextError(false);

                                    const trimmed = value.replace(/\s/g, "");
                                    let isValid = false;

                                    let text;
                                    if (
                                      contact.contactType === CONTACT_TYPE.EMAIL
                                    ) {
                                      isValid =
                                        CommonUtil.Validator.validateEmailAddress(
                                          trimmed
                                        );
                                      text = trimmed;
                                    } else if (
                                      contact.contactType ===
                                      CONTACT_TYPE.CELLPHONE
                                    ) {
                                      const masked =
                                        CommonUtil.CellPhone.format(trimmed);
                                      isValid =
                                        CommonUtil.Validator.validateCellPhone(
                                          masked
                                        );
                                      text = masked;
                                    }

                                    setContactErrors((prevState) => ({
                                      ...prevState,
                                      contactText:
                                        trimmed === "" ? false : !isValid,
                                    }));
                                    setContact((prevState) => ({
                                      ...prevState,
                                      contactText: text,
                                    }));
                                  }}
                                  onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                      if (contact.contactText !== "") {
                                        if (!contactErrors.contactText) {
                                          e.target.blur();
                                          setShowConfirm(true);
                                        } else {
                                          setShowContactTextError(true);
                                        }
                                      }

                                      e.preventDefault();
                                    }
                                  }}
                                  error={contactErrors.contactText}
                                  disabled={loading}
                                />
                              </div>
                            </Grid.Column>

                            <Grid.Column width={4}>
                              <Button
                                size="small"
                                fluid
                                color="blue"
                                loading={loading}
                                icon="file alternate outline"
                                content="리포트 요청하기"
                                disabled={contact.contactText === "" || loading}
                                onClick={() => {
                                  if (contactErrors.contactText) {
                                    setShowContactTextError(true);
                                    return false;
                                  }

                                  setShowConfirm(true);
                                }}
                              />
                            </Grid.Column>
                          </Grid.Row>
                          {showContactTextError && (
                            <Grid.Row>
                              <Grid.Column>
                                <Message
                                  size={isDesktop ? "large" : "small"}
                                  error
                                  icon="exclamation triangle"
                                  header={
                                    contact.contactType === CONTACT_TYPE.EMAIL
                                      ? "이메일 주소가 잘못되었습니다."
                                      : "휴대전화번호가 잘못되었습니다."
                                  }
                                  content={
                                    contact.contactType === CONTACT_TYPE.EMAIL
                                      ? "이메일 주소를 정확히 입력해주세요."
                                      : "휴대전화번호를 정확히 입력해주세요."
                                  }
                                />
                              </Grid.Column>
                            </Grid.Row>
                          )}
                        </Grid>
                      </Form>
                      {isMobile && (
                        <CarmonPrivacyPolicy
                          linkTitle="이용 약관 보기"
                          style={{
                            width: "100%",
                            marginTop: "10px",
                            color: "#fff",
                            background: "#000",
                          }}
                        />
                      )}
                    </Segment>
                  </>
                )}

                {/*  <Modal.Actions>*/}
                {/*    <Button size={isDesktop ? 'large' : 'small'} content="닫기" icon="close"*/}
                {/*            onClick={() => resetAll()} />*/}
                {/*  </Modal.Actions>*/}
                {/*</Modal>*/}

                {requestResult && (
                  <Modal
                    open={true}
                    onClose={() => setRequestResult(false)}
                    closeOnDimmerClick={true}
                    closeOnEscape={true}
                    closeOnDocumentClick={true}
                    closeIcon={
                      <Icon
                        name={"times"}
                        size={"large"}
                        style={{ float: "right", margin: 10 }}
                      />
                    }
                    size="tiny"
                  >
                    <Modal.Content>
                      {requestResult ? (
                        <Header as="h2">
                          <div>
                            <Header.Content>
                              <Icon name="check" /> 리포트 요청 성공
                            </Header.Content>
                            {/*<Icon name={'times'} size={'small'} style={{float:'right'}} />*/}
                            {/*<div style={{clear:'both'}}></div>*/}
                          </div>

                          <Divider />
                          <Header.Subheader
                            style={{
                              wordWrap: "break-word",
                              wordBreak: "keep-all",
                            }}
                          >
                            고객님의 가격비교 리포트 요청이 정상적으로
                            접수되었습니다.
                            <br />
                            요청하신 리포트는 10분 내외로 받아보실 수 있습니다.
                            <br />
                            카나와 가격비교 서비스를 이용해주셔서 감사합니다.{" "}
                            <Icon name="smile outline" fitted />
                          </Header.Subheader>
                        </Header>
                      ) : (
                        <Header as="h2">
                          <Header.Content>
                            <Icon name="remove" /> 리포트 요청 실패
                          </Header.Content>
                          <Divider />
                          <Header.Subheader
                            style={{
                              wordWrap: "break-word",
                              wordBreak: "keep-all",
                            }}
                          >
                            고객님의 요청을 처리하는 중에 문제가 발생했습니다.
                            <br />
                            다시 한번 시도해 주시기 바랍니다. 이용에 불편을 드려
                            죄송합니다.
                          </Header.Subheader>
                        </Header>
                      )}
                    </Modal.Content>
                  </Modal>
                )}

                <Modal
                  size="tiny"
                  open={doClose}
                  onClose={() => setDoClose(false)}
                >
                  <Modal.Header>확인</Modal.Header>
                  <Modal.Content>
                    <Header>
                      카나와의 가격비교 리포트를 받아보세요.{" "}
                      <Icon name="smile outline" />
                    </Header>
                  </Modal.Content>
                  <Modal.Actions>
                    <Button
                      onClick={() => {
                        setDoClose(false);
                        resetAll();
                      }}
                    >
                      아니오
                    </Button>
                    <Button color="teal" onClick={() => setDoClose(false)}>
                      네
                    </Button>
                  </Modal.Actions>
                </Modal>

                <Modal
                  size="tiny"
                  open={showConfirm}
                  onClose={() => setShowConfirm(false)}
                >
                  <Modal.Header>리포트 요청하기</Modal.Header>
                  <Modal.Content style={{ wordBreak: "keep-all" }}>
                    <Header>
                      {contact.contactType === "EML"
                        ? "이메일 주소"
                        : "휴대전화"}{" "}
                      [{contact.contactText}] 로 카나와 가격비교 리포트를
                      받아보시겠습니까?
                    </Header>
                  </Modal.Content>
                  <Modal.Actions>
                    <Button onClick={() => setShowConfirm(false)}>
                      아니오
                    </Button>
                    <Button
                      color="teal"
                      onClick={() => {
                        setShowConfirm(false);
                        requestReport();
                      }}
                    >
                      네
                    </Button>
                  </Modal.Actions>
                </Modal>
              </Container>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </Container>
  );
};

export default CarnawaReq;
