import React from 'react';
import {Grid, Header, Image, Container, Divider, Menu} from 'semantic-ui-react';
import {CARMON_INFO} from '../constants/Consts';
import carmonLogo from '../images/carmon_logo.png';

export default function CarnawaFooterPage() {

  return (
    <footer id={'carmon-footer'} className={'carmon-footer'} style={{width:'100%', height:'100%', margin:'0 auto', background:'#222222'}}>
      <Grid style={{margin: 0, height:'100%'}} textAlign={'center'} verticalAlign={'middle'}>
        <Grid.Row>
          <Grid.Column>
            <Container text fluid style={{height: 'inherit', position: 'relative'}}>
              <Grid style={{margin: 0}} textAlign={'center'} verticalAlign={'middle'}>
                <Grid.Row>
                  <Grid.Column width={16}>
                    <Image src={carmonLogo}
                           verticalAlign={'top'}
                           size={'medium'}
                           style={{opacity: 1, width: '40vw'}}/>
                  </Grid.Column>
                  <Grid.Column width={16}>
                    카몬 | 주식회사 카몬스터
                  </Grid.Column>
                  <Grid.Column width={16}><Divider/></Grid.Column>
                  <Grid.Column width={16}>
              <span style={{textAlign:'left', fontSize: 12}}>
                <p>대표 | {CARMON_INFO.REPRESENTATIVE}</p>
                <p>개인정보관리책임자 | 성백환</p>
                <p>사업자등록번호 | {CARMON_INFO.BUSINESS_NUMBER}</p>
                <p>통신판매업신고 | {CARMON_INFO.SELLER_NUMBER}</p>
                <p>주소 | ({CARMON_INFO.POSTAL_CODE}) {CARMON_INFO.ADDRESS}</p>
                <p>고객센터 | {CARMON_INFO.SUPPORT_TEL}</p>
                <p>제휴문의 | <a href={`mailto:${CARMON_INFO.CONTACT_EMAIL}`} style={{textDecoration: 'none',}}>{CARMON_INFO.CONTACT_EMAIL}</a></p>
              </span>
                  </Grid.Column>
                  <Grid.Column width={16}><Divider/></Grid.Column>
                  <Grid.Column width={16}>
                    <Header as={'h4'}>
                      <Header.Content style={{color:'#fff'}}>카몬 서비스</Header.Content>
                    </Header>
                    <Menu secondary inverted compact>
                      <Menu.Item as="a"
                                 href={process.env.REACT_APP_CARMON_SITE_URL}
                                 target={'_blank'}
                                 content={'내차팔기'}
                                 icon={'external alternate'} />
                      {/*<Menu.Item as="a"*/}
                      {/*           href={process.env.REACT_APP_CARMON_DOT_COM_SITE_URL}*/}
                      {/*           target={'_blank'}*/}
                      {/*           content={'내차사기'}*/}
                      {/*           icon={'external alternate'} />*/}
                      <Menu.Item as="a"
                                 href={process.env.REACT_APP_CARNAWA_SITE_URL}
                                 target={'_blank'}
                                 content={'카나와'}
                                 icon={'external alternate'} />
                    </Menu>
                    <Divider/>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <span style={{textAlign:'center', fontSize: 12}}>
                      <p>(주)카몬스터는 판매자와 구매자가 서로 믿고 거래할 수 있는<br/>데이터 기반 중고차 거래 문화를 만들어 갑니다.</p>
                      <p>2022 © {CARMON_INFO.COMPANY_NAME}. All Rights Reserved.</p>
                    </span>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </footer>
  );
}