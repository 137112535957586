import { Header, Icon, Image, Message, Modal } from "semantic-ui-react";

import React from "react";
import carnawaLogo from "../../images/carnawa_logo.png";

const ServiceOutModal = () => {
  return (
    <Modal basic open={true} size="mini">
      <Image
        src={carnawaLogo}
        size="medium"
        centered
        style={{ marginBottom: "1rem" }}
      />
      <Header
        textAlign="center"
        as={"h2"}
        style={{ fontFamily: "paybooc-Medium" }}
      >
        서비스 개편 안내
      </Header>
      <Modal.Content
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <p>이용에 불편을 드려 죄송합니다.</p>
        <p>더 나은 서비스를 위해 서비스 개편 중입니다.</p>
        <p>정비 후 더 좋은 모습으로 찾아뵙겠습니다.</p>
        <Message
          color="black"
          compact
          style={{
            textAlign: "center",
          }}
        >
          <Message.Content>
            <p>문의사항은 고객센터를 이용해 주세요.</p>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                id="call_label"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Icon name="call" rotated="clockwise" />
                고객센터
              </div>
              <a href="tel:070-7576-4981">070-7576-4981</a>
            </div>
            <p>(평일 10:00 ~ 17:30)</p>
          </Message.Content>
        </Message>
      </Modal.Content>
    </Modal>
  );
};

export default ServiceOutModal;
